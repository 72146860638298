/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { styled, css } from 'stitches.config';
import { Link as RouterLink } from 'react-router-dom';

import { globalConst, ExternalLinks } from 'common/constant/GlobalConstant';

import * as SeparatorPrimitive from '@radix-ui/react-separator';

// DOM
export const Footer = () => (
  <Container>
    <ServiceName>{globalConst.serviceName}</ServiceName>
    <FooterMenu>
      <InternalLink to="/term-of-use">利用規約</InternalLink>
      <Separator decorative orientation="vertical" />
      <Link href={ExternalLinks.PrivacyPolicy} target="_blank" rel="noopener noreferrer">
        プライバシーポリシー
      </Link>
      <Separator decorative orientation="vertical" />
      <Link href={ExternalLinks.CookieNotices} target="_blank" rel="noopener noreferrer">
        Cookieに関する通知
      </Link>
      <Separator decorative orientation="vertical" />
      <Link href={`mailto:${globalConst.contactUsEmailAddress}`}>お問い合わせ</Link>
    </FooterMenu>
    <CopyRight css={{ marginBottom: '1.4rem' }}>
      © {currentYear()}. 詳細は
      <LinkInCopyRightInternal to="/term-of-use">利用規約</LinkInCopyRightInternal>
      をご覧ください。
      <br />
      Deloitte（デロイト）とは、デロイト トウシュ トーマツ
      リミテッド（“DTTL”）、そのグローバルネットワーク組織を構成するメンバーファームおよびそれらの関係法人（総称して“デロイトネットワーク”）のひとつまたは複数を指します。DTTL（または“Deloitte
      Global”）ならびに各メンバーファームおよび関係法人はそれぞれ法的に独立した別個の組織体であり、第三者に関して相互に義務を課しまたは拘束させることはありません。DTTLおよびDTTLの各メンバーファームならびに関係法人は、自らの作為および不作為についてのみ責任を負い、互いに他のファームまたは関係法人の作為および不作為について責任を負うものではありません。DTTLはクライアントへのサービス提供を行いません。詳細は
      <LinkInCopyRightExternal href={ExternalLinks.GroupInformation} target="_blank" rel="noopener noreferrer">
        www.deloitte.com/jp/about
      </LinkInCopyRightExternal>
      をご覧ください。
    </CopyRight>
  </Container>
);

// const and helpers
const currentYear = () => new Date().getFullYear();

// Styles
const Container = styled('footer', {
  width: 'min(1250px, 90dvw)',
  margin: 'auto',
  display: 'grid',
  fontFamily: '$Noto',
  padding: '2em 0',
  gap: 40,
});

const ServiceName = styled('h2', {
  fontFamily: '$OpenSans',
  fontSize: 30,
  fontWeight: 600,
  color: '#041E42',
  display: 'grid',
  alignContent: 'start',
  gap: 15,
});

const FooterMenu = styled('div', {
  display: 'grid',
  placeItems: 'start',
  gap: '1.5dvw',
  '@sm': {
    display: 'flex',
    alignItems: 'center',
    gap: 'calc(5px + 1.5dvw)',
  },
});

const linkStyle = css({
  fontSize: 'clamp(0.84375rem, 0.7789rem + 0.3241vw, 1.0625rem)',
  textDecoration: 'none',
  color: '#53565a',
  '&:hover': {
    textDecoration: 'underline',
  },
});
const Link = styled('a', linkStyle, {});
const InternalLink = styled(RouterLink, linkStyle, {});

const Separator = styled(SeparatorPrimitive.Root, {
  '@sm': {
    backgroundColor: '$gray8',
    height: '100%',
    width: 1,
  },
});

const CopyRight = styled('small', {
  fontSize: 12,
  color: '$gray10',
  lineHeight: 1.5,
});

const LinkInCopyRight = css({
  fontSize: 12,
  color: 'inherit',
});
const LinkInCopyRightExternal = styled('a', LinkInCopyRight, {});
const LinkInCopyRightInternal = styled(RouterLink, LinkInCopyRight, {});
