import { lazy } from 'react';

interface LazyPreload<Props> extends React.LazyExoticComponent<React.ComponentType<Props>> {
  prefetch: () => void;
}

export const ReactLazyPreload = <Props>(importStatement: () => Promise<{ default: React.ComponentType<Props> }>) => {
  const Component: LazyPreload<Props> = Object.assign(lazy(importStatement), {
    prefetch: importStatement,
  });

  return Component;
};
