import React, { ReactNode } from 'react';
import { styled, keyframes } from 'stitches.config';

import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { OnBoardingPaper } from './OnBoardingPaper';

type Props = {
  children: ReactNode;
};

// Animations
const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

// Styles
const DialogOverlay = styled(Dialog.Overlay, {
  background: 'rgba(0 0 0 / 0.5)',
  position: 'fixed',
  zIndex: '$4',
  inset: 0,
  display: 'grid',
  placeItems: 'center',
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

const DialogContent = styled(Dialog.Content, {
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  zIndex: '$max',
  width: '100%',
  height: '100%',
  maxWidth: '1350px',
  display: 'grid',
  justifyItems: 'center',
  padding: '2em 0.5em',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '@md': {
    width: '90dvw',
    height: '90dvh',
    padding: '3.5em',
  },
  overflowY: 'auto',
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
});

const IconButton = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 36,
  width: 36,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  position: 'absolute',
  top: 18,
  right: 18,
  boxShadow: '0 0 6px #00000029',
  border: 'none',

  '&:hover': { backgroundColor: '$gray9' },
  '&:focus': { boxShadow: `0 0 0 2px $gray8` },
});

// DOM
export const OnBoardingModal: React.FC<Props> = ({ children }) => (
  <Dialog.Root>
    <Dialog.Trigger asChild>{children}</Dialog.Trigger>
    <Dialog.Portal>
      <DialogOverlay />
      <DialogContent>
        <OnBoardingPaper />
        <Dialog.Close asChild>
          <IconButton aria-label="Close">
            <Cross2Icon />
          </IconButton>
        </Dialog.Close>
      </DialogContent>
    </Dialog.Portal>
  </Dialog.Root>
);
