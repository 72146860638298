import React from 'react';
import { styled } from 'stitches.config';

export const TermsText = () => (
  <Document>
    <Heading>利用規約</Heading>
    <Premises>前文</Premises>
    <Whereas>{Whereases}</Whereas>
    {TermOfUse.map((article) => (
      <Article key={article.title}>
        <ArticleHeading>{article.title}</ArticleHeading>
        <ul>
          {article.paragraphs.map((paragraph) => (
            <Paragraph key={paragraph} prefix={article.paragraphs.length !== 1}>
              {paragraph}
            </Paragraph>
          ))}
        </ul>
      </Article>
    ))}
    <Closing>制定：２０２３年８月３１日</Closing>
  </Document>
);

const Document = styled('div', {
  margin: 'auto',
  maxWidth: '90%',
});

const Heading = styled('h2', {
  textAlign: 'center',
  fontSize: 28,
  margin: '0 0 50px',
});

const Premises = styled('h3', {
  textAlign: 'center',
  fontSize: '1.3rem',
  margin: '0 0 40px',
});

const Whereas = styled('p', {
  fontSize: '0.9rem',
  lineHeight: 1.5,
  letterSpacing: '0.05em',
  margin: '0 0 40px',
});

const ArticleHeading = styled('h3', {
  fontSize: '1.2rem',
  margin: '0 0 40px -1em',
});

const Article = styled('ol', {
  counterReset: 'item',
  listStyleType: 'none',
  margin: '0 0.5rem 40px 0',
});

const Paragraph = styled('li', {
  all: 'unset',
  display: 'block',
  fontSize: '0.9rem',
  letterSpacing: '0.05em',
  lineHeight: 1.5,
  position: 'relative',
  verticalAlign: 'top',
  whiteSpace: 'pre-line',
  marginBottom: '1.5em',
  variants: {
    prefix: {
      true: {
        left: 'none',
        '&::before': {
          left: '-1.5em',
          position: 'absolute',
          content: 'counter(item) "."',
          counterIncrement: 'item',
        },
      },
      false: {
        left: '-1.5em',
      },
    },
  },
});

const Closing = styled('p', {
  fontSize: '0.9rem',
  lineHeight: 1.5,
  letterSpacing: '0.05em',
});

const Whereases =
  '本利用規約（以下「本規約」といいます。）は、本ウェブサイト（第2条において定義されます。）においてデロイト トーマツ ファイナンシャルアドバイザリー合同会社が提供する本サービス（第2条において定義されます。）の内容、本サービスの提供条件その他本サービスの提供及び利用に関する当社及び利用者様間の権利義務関係を定めるものです。本サービスをご利用いただく場合には、事前に本規約の全文をお読みいただいた上で、本規約に同意していただく必要があります。';

const TermOfUse = [
  {
    title: '第１条（本規約の目的及び適用範囲）',
    paragraphs: [
      '本規約は、本サービスの内容、本サービスの提供条件その他本サービスの提供及び利用に関する当社及び利用者様間の権利義務関係を定めることを目的とするものです。本サービスの提供及び利用に関する当社と利用者様の間のいかなる関係についても、本規約が適用されるものとします。',
      '本規約の内容と、その他の本ウェブサイト（第2条において定義されます。）又は本サービスに関する説明資料やサービス使用許諾契約書（第2条において定義されます。）等における記載の間に齟齬がある場合には、本規約の規定が優先して適用されるものとします。',
      '本規約のいずれかの規定又はその一部が、無効又は執行不能と判断された場合であっても、他の規定又は当該規定の他の部分は、引き続き完全にその効力を有するものとします。',
    ],
  },
  {
    title: '第２条（定義）',
    paragraphs: [
      [
        `本規約において、以下の用語は、それぞれ以下に定める意味を有するものとします。`,
        ``,
        `（１）「本ウェブサイト」とは、当社が管理運営するウェブサイトである「Trend Insights」（https://trend-insights.fa-biz.deloitte.jp/）をいいます。`,
        ``,
        `（２）「本サービス」とは、当社が本ウェブサイトにおいて提供する、webから収集した記事データを整理し、検索機能と共に提供するシステム、その他の付随サービスの総体としてのサービスをいいます。`,
        ``,
        `（３）「知的財産権」とは、特許権、実用新案権、意匠権、著作権、商標権その他の一切の知的財産権をいいます。`,
      ].join('\n'),
    ],
  },
  {
    title: '第３条（本サービスの利用）',
    paragraphs: [
      '利用者様は、本サービスを利用するに当たり、本規約及び本ウェブサイトに掲載の各種定めを遵守しなければならないものとします。',
      '当社は、当社の判断により、利用者様の便宜のために本サービスの機能・サービス内容の詳細・利用方法等についての資料を提供するものとします。',
      'その他サービスの詳細については、本ウェブサイトに掲載するものとします。',
    ],
  },
  {
    title: '第４条（本サービス及び本サービス利用上の注意）',
    paragraphs: [
      '利用者様は、本利用規約及び適用される法律を遵守する限り、本サービスにアクセスしサービスを利用することができます。',
      '検索方法等により、本サービスの検索結果が利用者様の意図と合致しないことがあります。検索結果が利用者様の意図と異なる場合も、当社はいかなる責任も負いかねます。',
      '本サービスの検索結果を無断で転載することは、固く禁じます。',
      '本サービスは、利用者様の私的利用に限定し、ビジネス目的での利用及び第三者への開示は、固く禁じます。',
    ],
  },
  {
    title: '第５条（禁止事項）',
    paragraphs: [
      [
        `利用者様は、本サービスを利用するに当たって、以下の各号に該当する行為をしてはならないものとします。`,
        ``,
        `(1) 犯罪行為その他法令等に違反する行為`,
        ``,
        `(2) 当社及び第三者に対する詐欺又は脅迫行為`,
        ``,
        `(3) 当社及び第三者の営業を妨害し又は信用を毀損する行為`,
        ``,
        `(4) 当社及び第三者の知的財産権、名誉権、プライバシー権その他の権利又は利益を侵害する行為`,
        ``,
        `(5) 以下に該当する表現を含む内容のメッセージを本ウェブサイト上に登録若しくは投稿し又は当社及び第三者に送信等する行為`,
        ``,
        [
          `ア 暴力的又は威嚇的な表現`,
          ``,
          `イ わいせつ又はひわいな表現`,
          ``,
          `ウ 特定の人種、民族、宗教、性的指向等に対する差別を誘引又は助長する表現`,
          ``,
          `エ 上記のほか第三者に不快感を与える表現`,
          ``,
        ]
          .map((item) => `\u2003${item}`)
          .join('\n'),
        `(6) チェーンメール、スパムメールその他の迷惑メールを当社及び第三者に送信等する行為`,
        ``,
        `(7) コンピューター・ウィルスその他の有害なコンピューター・プログラムを拡散する行為`,
        ``,
        `(8) 当社及び第三者になりすます行為`,
        ``,
        `(9) 当社及び第三者の商号、ロゴその他の表示を用いて、これらの者との関係を不当に誤認させる可能性がある情報を登録する行為`,
        ``,
        `(10) 本ウェブサイトのネットワーク又はシステム等に過度な負荷をかける行為`,
        ``,
        `(11) 本ウェブサイトのネットワーク又はシステム等に不正にアクセスする行為`,
        ``,
        `(12) 広告、宣伝、勧誘、営業行為その他本ウェブサイトの趣旨に反する行為`,
        ``,
        `(13) 不当に本サービスの利用に係る料金の支払義務を免れようとする行為`,
        ``,
        `(14) 上記のほか、本ウェブサイト又は本サービスの適切な管理運営及び提供を妨げる又はそのおそれがあると当社が判断する行為`,
        ``,
      ].join('\n'),
    ],
  },
  {
    title: '第６条（本サービスの一時停止等）',
    paragraphs: [
      [
        `当社は、以下の各号のいずれかに該当する場合には、本サービスの全部又は一部の提供を一時停止又は中断することができるものとします。この場合、当社は、事前の通知が可能である場合には事前に、不可能である場合には本サービスの全部又は一部の提供を一時停止又は中断した後合理的期間内に、その旨を公表し、又は、利用者様に通知するものとします。`,
        ``,
        `(1)本ウェブサイトに係るサーバー、ネットワークその他のコンピューター・システム又は通信回線、設備等の点検、保守、修理又は変更作業を行う場合`,
        ``,
        `(2)本ウェブサイトに係るサーバー、ネットワークその他のコンピューター・システム又は通信回線、設備等の使用が地震、落雷、火災、風水害、天災地変、停電等の不可抗力により停止又は中断した場合`,
        ``,
        `(3)行政当局又は裁判所等から本サービスの全部又は一部の提供を一時停止又は中断するよう指導、勧告、命令等を受けた場合`,
        ``,
        `(4)上記のほか、当社が合理的理由により本サービスの全部又は一部の一時停止又は中断が必要であると判断した場合`,
        ``,
      ].join('\n'),
      `前項の場合に加えて、当社は、当社の合理的判断により、いつでも本サービスの内容を変更し又は提供を終了することができるものとします。当社は、本サービスの提供を終了する場合には、事前にその旨を公表します。当社が本サービスの提供を終了する場合には、当該終了の時点において当社及び利用者様間の本規約に基づく契約関係は終了するものとします。`,
      `利用者様は、前2項に基づく措置がなされた場合でも、本ウェブサイト又は本サービスに関して当社、他の利用者様その他の第三者に負う債務及び義務を一切免れることはできないものとします。`,
      `当社は、本条に基づき当社が行った措置により利用者様に損害等が生じた場合でも、当該損害等について一切責任を負わないものとします。`,
    ],
  },
  {
    title: '第７条（知的財産権の帰属）',
    paragraphs: [
      `本ウェブサイト及び本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾しているライセンサーに帰属するものとし、本ウェブサイト及び本サービスに対する会員登録並びに本サービスの提供は、何ら当該知的財産権の利用者様に対するライセンスを意味するものではないものとします。`,
    ],
  },
  {
    title: '第８条（当社による情報の閲覧及び利用）',
    paragraphs: [
      `当社は、本ウェブサイト及び本サービスが利用者様の特定の目的に適合すること、利用者様が期待する機能、商品的価値、正確性若しくは有用性を有すること、利用者様による本ウェブサイト及び本サービスの利用が利用者様に適用される法令等に適合すること、本サービスの利用に不具合が生じないこと、又は本ウェブサイト及び本サービスにおいて当社又は他の利用者様が提供する情報が真実かつ正確であることのいずれについても何ら保証するものではありません。`,
      `当社は、システムやサーバーのダウン、当社による本サービスの提供の一時停止、中断、終了、利用不能又は変更、登録情報の削除又は消失、会員登録の抹消、本サービスの利用によるデータの喪失又は機器の故障若しくは損傷その他理由の如何を問わず、また、債務不履行又は不法行為その他請求の原因の如何を問わず、本サービスに関連して利用者様が被った損害等について一切の責任を負いません。`,
      `利用者様は、自らの責任と判断で本サービスを利用するものとし、当社は、本サービスに関連して利用者様と他の利用者様又は第三者との間において生じた取引、契約、連絡、交渉、協議、紛争等について一切の責任を負わないものとします。`,
    ],
  },
  {
    title: '第９条（秘密保持義務）',
    paragraphs: [
      `利用者様は、本サービスの利用に関連して取得した当社の機密情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとし、第三者に開示又は漏えいしないものとします。また、利用者様は、本サービスの利用に関連して取得した当社の機密情報（本ウェブサイト及び本サービスに関する情報・仕組み・ノウハウ・プログラムソース等を含みますがこれらに限られません。）について、自ら又は第三者のために利用してはならないものとします。`,
    ],
  },
  {
    title: '第１０条（本規約上の地位の譲渡）',
    paragraphs: [
      `利用者様は、当社が事前に書面によって承諾しない限り、本規約上の地位又は本規約に基づく権利義務を、第三者に対して、譲渡、移転、担保設定その他の処分をすることはできないものとします。`,
      `当社は、本サービス及び本ウェブサイトに係る事業を他社に譲渡する場合には、本規約上の地位又は本規約に基づく権利義務を、当該事業の譲受人に譲渡することができるものとします。`,
    ],
  },
  {
    title: '第１１条（準拠法及び管轄裁判所）',
    paragraphs: [
      `本規約は、日本法を準拠法とし、日本法に従って解釈されるものとします。本規約に起因し又は関連して生じる一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。`,
    ],
  },
  {
    title: '第１２条（本規約の変更）',
    paragraphs: [
      `当社は、いつでも本規約を変更することができるものとします。当社は、本規約を変更した場合には、直ちに利用者様の管理者にその旨を通知します。利用者様が本規約の変更後も本サービスの利用を継続するためには、その管理者が当該変更内容に同意する必要があります。`,
    ],
  },
  {
    title: '第１３条（存続条項）',
    paragraphs: [
      `第1条、第4条、第6条第3項及び第4項、第7条、第8条、第9条、第10条、第11条並びに本条の規定は、当社及び利用者様間の本規約に基づく契約関係が終了した後も引き続き有効にその効力を有するものとします。`,
    ],
  },
];
