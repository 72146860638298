import { FC, ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { styled } from 'stitches.config';

import { MapResizer } from 'common/components/mapResizer/mapResizer';
import { Header } from './header/Header';

type Props = {
  title: string;
  description: string;
  children: ReactNode;
};

/**
 * 全体のレイアウト、スタイリングを指定します。
 * @param title: ページタイトル
 */

// Style
const Content = styled('main', {
  backgroundColor: '$Cream',
});

// eslint-disable-next-line arrow-body-style
export const Layout: FC<Props> = ({ title, description, children }) => {
  // States and Behaviors
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        <Content>
          <Header />
          {children}
        </Content>
      </HelmetProvider>

      <MapResizer />
    </>
  );
};
