import React from 'react';
import { styled, keyframes, Text } from 'stitches.config';

export const InitialLoad = () => (
  <BackGround css={{ display: 'grid', placeItems: 'center', height: '100dvh' }}>
    <Container>
      <TextWithDynamicUnderLine
        sans
        css={{ fontSize: 'clamp(2.5rem, 2.2256rem + 1.1707vw, 3.25rem)', fontWeight: '$700' }}
      >
        Please Wait ...
      </TextWithDynamicUnderLine>

      <Text noto css={{ fontSize: 'clamp(1.25rem, 1.1128rem + 0.5854vw, 1.625rem)' }}>
        初回セットアップ中です
      </Text>
    </Container>
  </BackGround>
);

// Animations
const sliding = keyframes({
  from: {
    backgroundSize: '0% 0%',
  },
  to: {
    backgroundSize: '100% 100%',
  },
});

// Styles
const BackGround = styled('div', {});

const Container = styled('div', {
  display: 'grid',
  placeItems: 'center',
  width: 'min(800px, 91dvw)',
  alignSelf: 'center',
  gap: '6dvh',
});

const TextWithDynamicUnderLine = styled(Text, {
  backgroundImage: 'linear-gradient(to top, $AccessibleGreen 3px, transparent 3px)',
  backgroundPosition: 'left bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '0% 100%',
  animationName: `${sliding}`,
  animationDuration: '0.3s, 0s',
  animationDelay: '0.1s',
  animationFillMode: 'forwards',
});
