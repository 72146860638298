import { atom } from 'jotai';

import { DATA_SOURCE, PeriodYear, PERIOD_RANGE } from './types';
import type { DataSource } from './types';

// helper
const current = () => PERIOD_RANGE.slice(-1)[0];
const twoYearsAgo = () => PERIOD_RANGE.slice(-3, -1)[0];

// atoms
export const queryKeyWord = atom<string>('');
export const queryPeriod = atom<[PeriodYear, PeriodYear]>([twoYearsAgo(), current()]); // 初期値は直近３年間
export const allPeriodsSelected = atom(false);
export const targetDataSource = atom<DataSource>(DATA_SOURCE.PRTIMES);

/**
 * 合成された現在の検索クエリ。変更がseverDataをfetchするatomへ伝播する。
 */
export const query = atom((get) => {
  const isAll = get(allPeriodsSelected);
  const min = isAll ? '' : Math.min(...get(queryPeriod));
  const max = isAll ? '' : Math.max(...get(queryPeriod));

  return `k=${get(queryKeyWord)}&rs=${min}&re=${max}&es=prtimes_page`;
});
