export const scaleWithResults = (numOfResults: number) => {
  const minScale = 1;
  const maxScale = 2;
  const plainScale = () => 0.8 + numOfResults / 500;

  return (n: number) => n * clamp(plainScale(), minScale, maxScale);
};

export const expand = (threshold: number) => {
  const minScale = 1;
  const maxScale = 2.5;

  const scaleInversely = (size: number) => threshold / size;

  return (size: number) => size * clamp(scaleInversely(size), minScale, maxScale);
};

const clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(value, max));
