import { FC } from 'react';
import { styled } from 'stitches.config';

import usageImg from 'assets/usage_image_1.webp';

/**
 * 初期ビュー(マップ部分のプレースホルダー)のコンポーネントです。
 * プロダクトの説明を表示します。
 */

type Props = {
  callTo: string;
};

// DOM
export const CallToAction: FC<Props> = ({ callTo }) => (
  <Flex>
    <FlexItem css={{ flex: 5 }}>
      <Concept>
        チームの情報収集力は、
        <br />
        情報検索AIで高める。
      </Concept>
      <Description>
        ビジネストレンドを立体的視点によりビジュアライズし、効率的な情報収集と予想外の繋がりの発見をサポートします。
      </Description>
      <PRTimesPR>月間6,000万PVを誇るPR TIMESのデータがキーワードを入れるだけで検索可能</PRTimesPR>
      <Button href={callTo}>検索してみる</Button>
    </FlexItem>
    <UsageImage src={usageImg} alt="usage" css={{ flex: 5 }} />
  </Flex>
);

// Styles
const Flex = styled('div', {
  '@md': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '3em',
  },
});

const FlexItem = styled('div', {
  display: 'grid',
  gap: '1.3em',
});

const Concept = styled('h2', {
  fontSize: '$head2',
  fontFamily: '$Noto',
  letterSpacing: 3,
  lineHeight: 1.4,
  textDecoration: 'underline',
  textDecorationThickness: '0.2em',
  textDecorationColor: '$DeloitteGreen',
  textUnderlineOffset: '-0.05em',
  textDecorationSkipInk: 'none',
  color: '#2A2A2A',
});

const Description = styled('p', {
  fontSize: '$body2',
  fontFamily: '$Noto',
  lineHeight: '1.5em',
  letterSpacing: 2,
  minWidth: '20ch',
  color: '$AnnotationText',
});

const PRTimesPR = styled('p', {
  fontSize: 13,
  fontFamily: '$Noto',
  lineHeight: '1.5em',
  fontWeight: '$600',
  letterSpacing: 1.2,
  color: '$Text',
  maxWidth: '40em',
});

const Button = styled('a', {
  all: 'unset',
  width: 'calc(14dvw + 23px)',
  backgroundColor: '$AccessibleGreen',
  borderRadius: '$2',
  color: 'white',
  fontSize: 'clamp(0.7rem, 0.58rem + 0.6481vw, 1.3125rem)',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  padding: 'min(1.5dvw, 1em) min(1.5dvh, 2em)',
  boxShadow: `-1px 1px 5px rgba(0,0,0,0.3)`,
  '&:hover': {
    filter: 'brightness(80%)',
    cursor: 'pointer',
    boxShadow: 'none',
  },
});

const UsageImage = styled('img', {
  maxInlineSize: '50dvh',
  minInlineSize: '50%',
  blockSize: 'auto',
  filter: 'drop-shadow(-2px 2px 5px rgba(0,0,0,0.3))',
});
