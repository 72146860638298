import { FC, useState, useEffect } from 'react';
import { styled } from '@stitches/react';
import axios from 'axios';
import { FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import { FeaturedWords } from 'common/components/featuredWords/FeaturedWords';
import { PeriodController } from 'common/components/periodController/PeriodController';
import { SearchWithPopper } from 'common/components/search/SearchWithPopper';
import { LoginModal } from 'common/components/login/loginMordal/LoginModal';

import { Box, Inner } from './Inner';

const TIME_OUT_ERROR_MESSAGE = [
  'いつもご利用いただき、誠にありがとうございます。',
  '',
  'おかげさまで多くの方にご利用いただいており、一時的につながりにくい状況です。',
  '少し時間をおいていただくと、快適にご利用できるようになります。',
  '',
  'またのご利用を心からお待ちしております。',
].join('\n');
const TOKEN_EXPIRED_MESSAGE = 'セッションが切れました。もう一度ログインしてください。';
const SERVER_ERROR_MESSAGE = 'サーバーエラーが発生しました。恐れ入りますが、時間を置いてもう一度お試しください。';
const UNEXPECTED_ERROR_MESSAGE = '予期せぬエラーが発生しました。管理者までご連絡ください。';

/**
 * chidrenのコンポーネントが搬送したerrorをキャッチし、エラー画面を表示します。
 */

const Flex = styled('div', Box, {});

const Container = styled('div', Inner, {
  fontFamily: '$Noto',
  padding: '2.5rem',
});

const Heading = styled('h1', {
  fontSize: '$head1',
  fontWeight: 'bold',
  letterSpacing: 3,
  color: 'black',
  marginBottom: '0.3rem',
  textShadow: `1px 1px 1px #000000,
  -1px 1px 1px #000000,
  1px -1px 1px #000000,
  -1px -1px 1px #000000,
  1px 0px 1px #000000,
  0px 1px 1px #000000,
  -1px 0px 1px #000000,
  0px -1px 1px #000000`,
});

const Message = styled('p', {
  body2: 'clamp(12px, 1vw, 15.5px)',
  maxWidth: '31em',
  whiteSpace: 'pre-line',
  lineHeight: 1.25,
});

export const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  // State and Behaviors
  const [showLoginModal, setShowLoginModal] = useState(false);

  const parseError = (): { status: number; message: string } => {
    if (!axios.isAxiosError(error)) return { status: 0, message: UNEXPECTED_ERROR_MESSAGE };

    const { response: { status } = {} } = error;
    if (status === 504) return { status, message: TIME_OUT_ERROR_MESSAGE };
    if (status === 200) return { status, message: error.message };
    if (status === 403) return { status, message: TOKEN_EXPIRED_MESSAGE };
    return { status: status || 0, message: SERVER_ERROR_MESSAGE };
  };
  const { status, message } = parseError();

  useEffect(() => {
    if (status === 403) {
      setShowLoginModal(true);
    }
  }, [status]);

  const navigate = useNavigate();
  const handleSubmit = () => {
    resetErrorBoundary();
    navigate('/landscape');
  };

  return (
    <>
      <Flex>
        <Container>
          <Heading>Sorry...</Heading>
          <Message>{message}</Message>
          <SearchWithPopper
            maxWidth="100%"
            placeHolder={status === 200 ? '別の単語をお試しください' : '関心のある単語を入力...'}
            onSubmit={handleSubmit}
            popMessage="検索期間を変更しますか？"
            popContent={<PeriodController />}
          />
          {status === 200 && (
            <>
              <Message>
                もしくは以下のようなワードで検索すると、
                <br />
                有意な結果を得ることができます。
              </Message>
              <FeaturedWords onClick={handleSubmit} />
            </>
          )}
        </Container>
      </Flex>

      <LoginModal isOpen={showLoginModal} openChange={setShowLoginModal} reLogin />
    </>
  );
};
