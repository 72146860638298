import React from 'react';
import { styled } from 'stitches.config';
import { PeriodRangeSlider } from 'common/components/periodController/PeriodRangeSlider';
import { AllRangeSwitch } from 'common/components/periodController/AllRangeSwitch';

// Styles
const Container = styled('div', {
  display: 'grid',
  placeItems: 'center',
  gap: '1.3em',
});

// DOM
export const PeriodController: React.FC = () => (
  <Container>
    <PeriodRangeSlider />
    <AllRangeSwitch />
  </Container>
);
