import { styled } from 'stitches.config';

import DeloitteLogo from 'assets/deloitte_logo.png';

import { Index } from 'common/components/login/auth/Index';
import { WidthContainer } from './WidthContainer';

// DOM
export const FirstRow = () => (
  <Background>
    <WidthContainer>
      <Flex>
        <Logo>
          <Deloitte src={DeloitteLogo} alt="logo" />
        </Logo>

        <Index />
      </Flex>
    </WidthContainer>
  </Background>
);

// Styles
const Background = styled('div', {
  backgroundColor: 'black',
});

const Flex = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Logo = styled('h1', {
  height: 50.39,
  display: 'grid',
  alignItems: 'center',
});

const Deloitte = styled('img', {
  height: 25,
});
