import { FC, ReactNode, useState } from 'react';
import { styled } from 'stitches.config';
import { useAtomValue, useSetAtom } from 'jotai';
import { useForm, SubmitHandler } from 'react-hook-form';

import * as Popover from '@radix-ui/react-popover';
import { InfoCircledIcon } from '@radix-ui/react-icons';

import { user } from 'models/user/atom';
import { queryKeyWord } from 'models/queryState/atoms';
import { countUpSearches } from 'common/hooks/useSurvey';

import { Search } from './Search';
import { ValidationMessage, validationRules } from './Validation';

type Props = {
  maxWidth: string;
  placeHolder: string;
  onSubmit: (currentUser: any) => void;
  onFocus?: () => void;
  popMessage: string;
  popContent: ReactNode | 'none';
};

/**
 * active時、真下に出現するpopperを持つサーチバーです。
 * 渡されたcontentを表示します。
 */

// DOM
export const SearchWithPopper: FC<Props> = ({ maxWidth, placeHolder, onSubmit, onFocus, popMessage, popContent }) => {
  const [focusInput, setFocusInput] = useState(false);
  const setQuery = useSetAtom(queryKeyWord);
  const currentUser = useAtomValue(user);

  type Input = { word: string };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>();

  const submitAction: SubmitHandler<Input> = (data) => {
    countUpSearches();
    setQuery(data.word);
    onSubmit(currentUser);
  };

  return (
    <Popover.Root open={focusInput}>
      <SearchForm
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitAction)}
        validationMessage={<ValidationMessage message={errors.word?.message} />}
        maxWidth={maxWidth}
      >
        <Anchor css={{ flex: 5 }} onFocus={onFocus}>
          <Input
            placeholder={placeHolder}
            autoComplete="off"
            {...register('word', validationRules)}
            css={{ width: '100%' }}
            type="search"
            onFocus={() => setFocusInput(true)}
          />
        </Anchor>
        <Submit value="Explore!" type="submit" css={{ flex: 1.5, '@sm': { flex: 2 } }} />
      </SearchForm>

      <Popover.Portal>
        <StyledContent
          align="start"
          onOpenAutoFocus={(event: Event) => event.preventDefault()}
          onPointerDownOutside={() => setFocusInput(false)}
          onEscapeKeyDown={() => setFocusInput(false)}
          sideOffset={12}
        >
          <MessageRow>
            <InfoCircledIcon />
            {popMessage}
          </MessageRow>
          {popContent}
        </StyledContent>
      </Popover.Portal>
    </Popover.Root>
  );
};

// Styles
const SearchForm = styled(Search, {});
const Input = styled(Search.Input, {});
const Submit = styled(Search.Submit, {});

const StyledContent = styled(Popover.Content, {
  width: `min(24em, 90dvw)`,
  borderRadius: 3,
  padding: '2em',
  fontFamily: '$Noto',
  fontSize: 14,
  backgroundColor: '#F3F3F3',
  color: '$AnnotationText',
  display: 'grid',
  gap: '1.3em',
  zIndex: '$1',
  filter: 'drop-shadow(-1px 1px 4px rgba(0,0,0,0.3))',
});

const MessageRow = styled('p', {
  display: 'flex',
  gap: '0.3em',
  alignItems: 'center',
});

const Anchor = styled(Popover.Anchor, {
  all: 'unset',
});
