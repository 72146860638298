/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { fullAxios } from 'common/lib/authAPI';

export const Authenticating: React.FC = () => {
  const qp = new URLSearchParams(window.location.search);
  const code = qp.get('code');
  const state = qp.get('state');
  const navigate = useNavigate();

  // INFO: 開発ビルドでは以下ログイン処理は２回実行される。これは許容するしかない。https://react.dev/learn/synchronizing-with-effects#sending-analytics
  React.useEffect(() => {
    if (code) {
      fullAxios({
        withCredentials: true,
        url: `${process.env.REACT_APP_TRENDINSIGHTS_ORIGIN}/api/login`,
        method: 'post',
        data: {
          code,
          state,
        },
      }).then((res: any) => {
        localStorage.setItem('accessToken', res.data.access_token);
        localStorage.setItem('refreshToken', res.data.refresh_token);
        localStorage.setItem('idToken', res.data.id_token);
        navigate('/', { replace: true });
      });
    }
  }, []);

  return <div>ログイン中です</div>;
};
