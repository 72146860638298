import { css } from 'stitches.config';

export const Box = css('div', {
  width: '100dvw',
  height: '100dvh',
  marginTop: '1.5rem',
});

export const Inner = css('section', {
  width: 'min(93dvw, 600px)',
  margin: 'auto',
  borderRadius: '$4',
  boxShadow: '0 0 5px #00000028',
  display: 'grid',
  placeItems: 'start',
  gap: '2rem',
  backgroundColor: 'white',
});
