import { AxiosError, AxiosResponse } from 'axios';
import { reviver, Matrix } from 'mathjs';

import { fullAxios } from 'common/lib/authAPI';
import type { ServerData } from 'models/serverData/types';
import { apiRootEndURL } from './utills';

const NOTFOUND_ERROR_MESSAGE = '検索条件と十分に一致する結果が見つかりません。';

interface ServerResponse extends Omit<ServerData, 'tfidf_scores'> {
  tfidf_scores: JSON; // CompressedSparseColumn形式で返却されるため
}
const MatrixFromJSON = (json: JSON) => JSON.parse(JSON.stringify(json), reviver) as Matrix;

/**
 * ElasticSearchからのデータ取得をバックエンドへ依頼する。
 */
export const fetchDocument = async (query: string): Promise<ServerData> => {
  const endPoint = `${apiRootEndURL()}/api/cluster/documents?${query}`;
  const res: AxiosResponse<ServerResponse> = await fullAxios.get(endPoint, { withCredentials: true }); // MEMO: withCredentials: true いらないかも

  // Throw error if no result obtained
  if (Object.keys(res.data).length === 0) {
    throw new AxiosError(NOTFOUND_ERROR_MESSAGE, '', undefined, '', res);
  }

  return {
    // 検索ワードに関する全ての関連言葉
    invvocdict: res.data.invvocdict,
    // 全てのヒットした記事のid列
    idx: [...Array(res.data.embs.length).keys()],
    // 記事の座標
    embs: res.data.embs,
    // 記事×関連語のtf-idfスコアの行列
    tfidf_scores: MatrixFromJSON(res.data.tfidf_scores).toArray() as number[][],
    // ヒットした記事情報詳細
    vhits: res.data.vhits,
  };
};
