import { styled } from 'stitches.config';

import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';

import { ExternalLinks, globalConst } from 'common/constant/GlobalConstant';
import { WidthContainer } from './WidthContainer';

// DOM
export const ThirdRow = () => {
  type SisterSite = {
    name: string;
    url: string;
  };

  return (
    <Background>
      <WidthContainer>
        <NavBar aria-label="header-nav">
          <ChevronIcon as={ChevronLeftIcon} />
          <NavContent>
            {ExternalLinks.SISTER_SITES.map((site: SisterSite) => (
              <NavItems key={site.name}>
                <StyledLink
                  href={site.url}
                  aria-disabled={site.name === globalConst.serviceName ? 'true' : undefined}
                  target={site.name === globalConst.serviceName ? undefined : '_blank'}
                  rel={site.name === globalConst.serviceName ? undefined : 'noopener noreferrer'}
                  currentSevice={site.name === globalConst.serviceName}
                >
                  {site.name}
                </StyledLink>
              </NavItems>
            ))}
          </NavContent>
          <ChevronIcon as={ChevronRightIcon} />
        </NavBar>
      </WidthContainer>
    </Background>
  );
};

// Styles
const HorizontalScrollingThresholdPx = '800px';

const Background = styled('div', {
  display: 'grid',
  placeItems: 'center',
  backgroundColor: 'white',
  boxShadow: '0px 3px 6px #00000029',
});

const NavBar = styled('nav', {
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',

  [`@media screen and (max-width: ${HorizontalScrollingThresholdPx})`]: {
    justifyContent: 'center',
  },
});

const NavContent = styled('ul', {
  display: 'flex',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  [`@media screen and (max-width: ${HorizontalScrollingThresholdPx})`]: {
    width: '78dvw',
    alignItems: 'center',
  },
});

const ChevronIcon = styled('div', {
  display: 'none',

  [`@media screen and (max-width: ${HorizontalScrollingThresholdPx})`]: {
    display: 'block',
  },
});

const NavItems = styled('li', {
  all: 'unset',
  fontFamily: '$Noto',
  fontSize: 14,
  color: '$TextDarker',
  letterSpacing: '0.7px',
});

const StyledLink = styled('a', {
  all: 'unset',
  height: 40.19,
  boxSizing: 'border-box',
  padding: '8px 28px 7px',
  display: 'grid',
  placeItems: 'center',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '$AccessibleGreen',
    color: 'white',
  },
  variants: {
    currentSevice: {
      true: {
        backgroundColor: '$AccessibleGreen',
        color: 'white',
        cursor: 'default',
        '&:hover': 'none',
      },
    },
  },
});
