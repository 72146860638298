import React from 'react';
import { styled } from 'stitches.config';

import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

type Props = {
  children: React.ReactNode;
};

export const TermsArea: React.FC<Props> = ({ children }) => (
  <ScrollArea>
    <ScrollAreaViewport>{children}</ScrollAreaViewport>
    <ScrollAreaScrollbar orientation="vertical">
      <ScrollAreaThumb />
    </ScrollAreaScrollbar>
    <ScrollAreaCorner />
  </ScrollArea>
);

const SCROLLBAR_SIZE = 10;

const ScrollArea = styled(ScrollAreaPrimitive.Root, {
  overflow: 'hidden',
  background: 'white',
  boxShadow: '0 -1px 1px 0px rgba(230,230,230,0.8) inset',
});

const ScrollAreaViewport = styled(ScrollAreaPrimitive.Viewport, {
  maxWidth: 'min(1000px, 95dvw)',
  maxHeight: '58dvh',
});

const ScrollAreaScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: 'flex',
  // ensures no selection
  userSelect: 'none',
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: 'none',
  padding: 2,
  background: 'transparent',
  transition: 'background 160ms ease-out',
  '&:hover': { background: '$gray3' },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: SCROLLBAR_SIZE,
  },
});

const ScrollAreaThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: '$gray8',
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    minWidth: 20,
    minHeight: 20,
  },
});

const ScrollAreaCorner = styled(ScrollAreaPrimitive.Corner, {
  background: '$gray8',
});
