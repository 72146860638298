import React from 'react';
import { styled } from 'stitches.config';

type Props = {
  number: number;
  icon: React.ReactNode;
  title: string;
  description: string;
};

// DOM
export const FeatureCard: React.FC<Props> = ({ number, icon, title, description }) => (
  <Container>
    <Point>POINT {number}</Point>
    {icon}
    <Essential>{title}</Essential>
    <Description>{description}</Description>
  </Container>
);

// Styles
const BREAKPOINT = '220px';
const Container = styled('div', {
  fontSize: '$head2',
  fontFamily: '$Noto',
  background: 'white',
  border: '2px solid $AccessibleGreen',
  borderRadius: '15px',
  padding: '0.8em 0.5em',
  flex: `1 1 ${BREAKPOINT}`,
  display: 'grid',
  placeItems: 'center',
  gap: '0.5em',
});

const Point = styled('p', {
  fontFamily: '$OpenSans',
  fontSize: '22px',
  fontWeight: '$600',
  color: '$AccessibleGreen',
  letterSpacing: '0.03em',
});

const Essential = styled('h4', {
  fontSize: '20px',
  fontWeight: '$500',
  letterSpacing: '0.03em',
  marginTop: 'auto',
});

const Description = styled('p', {
  fontSize: '13px',
  letterSpacing: '0.03em',
  lineHeight: 1.6,
  color: '$AnnotationText',
});
