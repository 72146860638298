const wordsIndustry = [
  '金融',
  '建設',
  '物流',
  '不動産',
  'IT',
  'メディア',
  'エネルギー',
  '自動車',
  '電機',
  '食品',
  '生活用品',
  'サービス業',
  'エンタメ',
];

const wordsTechnology = [
  '自動運転車',
  'グリーン水素',
  '3Dプリンター',
  'NFT',
  '遠隔医療',
  'メタバース',
  'スマートマテリアル',
];

const wordsHot = [
  'デジタルツイン',
  'エシカル消費',
  'カーボンニュートラル',
  'ダイナミックプライシング',
  'ショールーミング',
  'ファンベース',
  '変異株',
  '無人店舗',
  '仮想空間',
];

export const recommendWords = [
  {
    data: wordsIndustry,
    phrase: '業界で探す',
  },
  {
    data: wordsTechnology,
    phrase: '新技術で探す',
  },
  {
    data: wordsHot,
    phrase: 'ホットトピック',
  },
];
