import { useState, useEffect } from 'react';

/**
 * クライアントのウィンドウサイズを取得するhookです。
 */

export const useWindowDimensions = () => {
  const useWindowSize = () => {
    const getWindowDimensions = () => {
      const { innerWidth: width, innerHeight: height } = window;

      return { width, height };
    };

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
      const onResize = () => {
        setWindowDimensions(getWindowDimensions());
      };
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }, []);
    return windowDimensions;
  };
  return useWindowSize;
};
