import React, { useEffect, useRef } from 'react';
import { styled } from 'stitches.config';
import { useSetAtom } from 'jotai';

import * as Separator from '@radix-ui/react-separator';

import { headerHeightAtom } from 'models/mapViewPortState/atom';

import { FirstRow } from './FirstRow';
import { SecondRow } from './SecondRow';
import { ThirdRow } from './ThirdRow';

// DOM
export const Header = () => {
  const containerRef = useRef<HTMLElement | null>(null);
  const setHeaderHeight = useSetAtom(headerHeightAtom);

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.offsetHeight;
      setHeaderHeight(height);
    }
  }, [containerRef, setHeaderHeight]);

  return (
    <Container ref={containerRef}>
      <FirstRow />
      <SecondRow />
      <SeparatorRoot decorative css={{ margin: '0 0' }} />
      <ThirdRow />
    </Container>
  );
};

// Styles
const Container = styled('header', {
  width: '100%',
  position: 'fixed',
  top: 0,
  zIndex: '$3',
});

const SeparatorRoot = styled(Separator.Root, {
  backgroundColor: '#DBDBDB',
  height: 1,
  width: '100%',
});
