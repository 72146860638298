import React from 'react';
import { styled } from 'stitches.config';
import { Link } from 'react-router-dom';

import { OnBoardingModal } from 'common/components/onBoarding/OnBoardingModal';
import { globalConst } from 'common/constant/GlobalConstant';
import { WidthContainer } from './WidthContainer';

// DOM
export const SecondRow: React.FC = () => (
  <Background>
    <WidthContainer>
      <NavBar aria-label="header-nav">
        <ThisService>{globalConst.serviceName}</ThisService>
        <Spacer />
        <NavContent>
          <NavItems>
            <StyledLink to="/">
              <Button onClick={() => window.scrollTo(0, 0)}>TOP</Button>
            </StyledLink>
          </NavItems>
          <NavItems>
            <OnBoardingModal>
              <Button>ご利用方法</Button>
            </OnBoardingModal>
          </NavItems>
        </NavContent>
      </NavBar>
    </WidthContainer>
  </Background>
);

// Styles
const Background = styled('div', {
  display: 'grid',
  placeItems: 'center',
  backgroundColor: 'white',
});

const NavBar = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  gap: 'none',
  justifyContent: 'stretch',
});

const Spacer = styled('div', {
  width: '100%',
  minWidth: 20,
  maxWidth: 102,
});

const NavContent = styled('ul', {
  display: 'flex',
  gap: 10,
  height: '100%',
  letterSpacing: '0.7px',
});

const NavItems = styled('li', {
  all: 'unset',
});

const ThisService = styled('h1', {
  fontFamily: '$OpenSans',
  fontSize: 16,
  fontWeight: 'bold',
  color: 'black',
  whiteSpace: 'nowrap',
});

const Button = styled('button', {
  all: 'unset',
  fontFamily: '$Noto',
  fontSize: 14,
  color: '$TextDarker',
  height: 57.19,
  boxSizing: 'border-box',
  padding: '0 10px',
  cursor: 'pointer',
  borderBottom: '3px solid transparent',
  transition: 'border-bottom-color 0.3s ease',
  whiteSpace: 'nowrap',
  '&:hover': {
    borderBottomColor: `${'$AccessibleGreen'}`,
    color: `${'$AccessibleGreen'}`,
  },
});

const StyledLink = styled(Link, {
  all: 'unset',
  height: '100%',
});
