/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
export const transpose = <T>(matrix: T[][]) => {
  if (matrix.length === 0) {
    return [];
  }
  return matrix[0].map((_, c) => matrix.map((r) => r[c]));
};

export const sliceByIdxs = <T>(array: T[], idxs: number[]) => {
  if (array.length < idxs.length) {
    throw Error('invalid args');
  }
  return idxs.map((idx) => array[idx]);
};

export const sortByKey = <T>(
  array: T[],
  keyFunc: { (_: T): number | string | Record<string, unknown> },
  order: 'asc' | 'desc'
) =>
  array.slice().sort((a, b) => {
    const ka = keyFunc(a);
    const kb = keyFunc(b);
    if (ka < kb) {
      if (order === 'asc') return -1;
      return 1;
    }
    if (ka > kb) {
      if (order === 'asc') return 1;
      return -1;
    }
    return 0;
  });

export const sum = (array: number[]) => {
  if (!Array.isArray(array)) {
    throw Error('arr must be Array');
  }
  return array.reduce((a, b) => a + b, 0);
};

export const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const apiRootEndURL = () => {
  switch (process.env.REACT_APP_SERVER_ENV) {
    case 'dev':
      return 'https://hm.fa-dev-01-00046.itps.deloitte.jp';

    case 'stg':
      return 'https://hm-api.stg.fa-biz.deloitte.jp';

    case 'prd':
      return 'https://hm-api.fa-biz.deloitte.jp';

    default:
      return '';
  }
};

export const selfRootURL = () => {
  switch (process.env.REACT_APP_SERVER_ENV) {
    case 'dev':
      return 'https://hm-cf2.fa-dev-01-00046.itps.deloitte.jp';

    case 'stg':
      return 'https://trend-insights.stg.fa-biz.deloitte.jp';

    case 'prd':
      return 'https://trend-insights.fa-biz.deloitte.jp/';

    default:
      return 'http://localhost:3000';
  }
};

interface TILocalStorage {
  searches: number;
  visits: number;
  lastSurveyDate: Date;
  lastVisitDate: number;
  agreedToTerms: boolean;
  isAnsweredList: boolean[];
}

export const getLocalStorage = <K extends keyof TILocalStorage>(key: K): TILocalStorage[K] | null => {
  const item = localStorage.getItem(key);
  if (!item) return null;

  try {
    switch (key) {
      case 'searches':
      case 'visits':
        return parseInt(item, 10) as TILocalStorage[K];
      case 'lastSurveyDate':
      case 'lastVisitDate':
        return new Date(item) as TILocalStorage[K];
      case 'agreedToTerms':
        return (item === 'true') as TILocalStorage[K];
      case 'isAnsweredList':
        return JSON.parse(item) as TILocalStorage[K];
      default:
        return null;
    }
  } catch (e) {
    return null;
  }
};

type LocalStorageNumber = number | null;

export const getCookieObject = () => {
  const arr: any = {};
  if (document.cookie !== '') {
    const tmp = document.cookie.split('; ');
    for (let i = 0; i < tmp.length; i++) {
      const data = tmp[i].split('=');
      arr[data[0]] = decodeURIComponent(data[1]);
    }
  }
  return arr;
};

export const getCookieValue = (key: string) => {
  const ko = getCookieObject();
  if (ko[key] !== undefined) {
    return ko[key];
  }
  return null;
};

export const getLocalStorageNumber = (key: string): LocalStorageNumber => {
  const rawCnt = localStorage.getItem(key);
  let cnt = null;
  if (rawCnt && /^-?\d+$/.test(rawCnt)) {
    cnt = parseInt(rawCnt, 10);
  }
  return cnt;
};

export const getDatetimeFormatBySeconds = (x: number): string => {
  const n = parseInt(x.toString(), 10);
  const h = Math.floor((n % (24 * 60 * 60)) / (60 * 60));
  const m = Math.floor(((n % (24 * 60 * 60)) % (60 * 60)) / 60);
  const s = ((n % (24 * 60 * 60)) % (60 * 60)) % 60;
  const hStr = h > 0 ? `${h.toString()}:` : '';
  const mStr = m > 9 ? `${m.toString()}:` : `0${m.toString()}:`;
  const sStr = s > 9 ? s.toString() : `0${s.toString()}`;
  return hStr + mStr + sStr;
};
