import React from 'react';
import { styled } from 'stitches.config';

import usageImg1 from 'assets/usage_image_1.webp';
import usageImg2 from 'assets/usage_image_2.webp';
import usageImg3 from 'assets/usage_image_3.webp';

import { Operation } from './Operation';

// styles
const Container = styled('section', {
  width: '100%',
  maxWidth: '900px',
  display: 'grid',
  justifyItems: 'center',
  alignContent: 'center',
  paddingRight: '1em',
});

const SectionHead = styled('h3', {
  fontSize: '$head2',
  fontFamily: '$OpenSans',
  fontWeight: '$600',
  letterSpacing: 2.5,
  marginBottom: '0.3em',
});

const SectionLead = styled('h3', {
  fontSize: '$body2',
  fontFamily: '$Noto',
  fontWeight: '$500',
  color: '$AccessibleGreen',
});

const Operations = styled('ul', {
  all: 'unset',
  display: 'flex',
  flexDirection: 'column',
  gap: '3em',
});

const Item = styled('li', {
  all: 'unset',
});

// DOM
export const OnBoardingPaper: React.FC = () => (
  <Container>
    <SectionHead>SCENE</SectionHead>
    <SectionLead css={{ marginBottom: '-4em' }}>ご利用の流れ</SectionLead>
    <Operations>
      <Item>
        <Operation number={1} image={usageImg1} imgSide="right" notes={notes[0]} />
      </Item>
      <Item>
        <Operation number={2} image={usageImg2} imgSide="left" notes={notes[1]} />
      </Item>
      <Item>
        <Operation number={3} image={usageImg3} imgSide="right" notes={notes[2]} />
      </Item>
    </Operations>
  </Container>
);

const notes = [
  {
    essentials: '結果を俯瞰',
    descriptions: [
      '情報密度の高い領域や、クラスター毎に抽出されたキーワードを確認します。',
      'また、検索結果に登場する企業の一覧を確認できます。',
    ],
  },
  {
    essentials: '情報の一覧にアクセス',
    descriptions: [
      '気になるトピックを含むワードクラウドを押すと、同クラスターを構成する記事を一覧表示します。',
      'または、操作パネルの企業名を押すと、同発行元の記事を一覧表示します。',
    ],
  },
  {
    essentials: '記事へアクセス / 検索の続行',
    descriptions: [
      '興味を惹く記事を発見出来たら、アクセス出来ます。',
      'または、操作パネル上から、検索条件を変更し更なる検索を行います。',
    ],
  },
];
