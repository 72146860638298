import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import App from './routes/App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // INFO: keycloakへログインフォーム入力した後のリダイレクト先のコンポーネントAuthenticatingのマウントロジックとして、
  // 認可コードの送信ロジックを書かざるを得ない。よってローカル動作させるためにはStrictModeをoffにせざるを得ない。
  // いいやり方が見つかれば改善したい。
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

reportWebVitals();
