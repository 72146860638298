/* eslint-disable @typescript-eslint/no-floating-promises */
import { atom } from 'jotai';

import { fullAxios, FaMateProfile } from 'common/lib/authAPI';
import { AxiosError } from 'axios';

type Anonymous = null;

export const user = atom(() => getFaMateProfile());

async function getFaMateProfile(): Promise<FaMateProfile | Anonymous> {
  try {
    const res = await fullAxios({
      url: `${process.env.REACT_APP_FAMBASE_ORIGIN}/api/user/users/me`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken()}`,
      },
    });
    return res.data as FaMateProfile;
  } catch (error: AxiosError | unknown) {
    if (!(error instanceof AxiosError)) {
      throw error;
    }

    return null;
  }
}

const accessToken = (): string | null => {
  const aToken = localStorage.getItem('accessToken');
  return aToken;
};
