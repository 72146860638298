import React from 'react';
import { styled } from 'stitches.config';
import { useNavigate } from 'react-router-dom';

import { Box, Inner } from './Inner';

// Styles
const Flex = styled('div', Box, {
  marginTop: '25dvh',
});

const Container = styled('div', Inner, {
  fontFamily: '$Noto',
  padding: '4rem 2rem',
  placeItems: 'center',
});

const Head = styled('h2', {
  fontSize: '$head1',
  fontWeight: 'bold',
});

const Paragraph = styled('p', {
  fontSize: '$body2',
  textAlign: 'start',
  lineHeight: 1.5,
});

const Button = styled('button', {
  fontSize: '15px',
  borderRadius: '$1',
  padding: '8px 12px',
  border: 'none',
  backgroundColor: '$AccessibleGreen',
  color: 'white',
  '&:hover': {
    filter: 'brightness(0.9)',
    backgroundColor: 'white',
    color: '$AccessibleGreen',
  },
});

export const NotFound = () => {
  // State and Behaviors
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };

  return (
    <Flex>
      <Container>
        <Head>NotFound</Head>
        <Paragraph>
          ご指定のページは見つかりませんでした。
          <br />
          トップページへお戻りください。
        </Paragraph>
        <Button onClick={handleClick}>トップページへ戻る</Button>
      </Container>
    </Flex>
  );
};
