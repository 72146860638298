import { FC, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { Home } from 'homePage/Home';
import { DisplayTerms } from 'displayTermsPage/DisplayTerms';
import { Authenticating } from 'loginPrprocess/Authenticating';
import { NotFound } from 'common/components/errorFallback/NotFound';
import { ErrorFallback } from 'common/components/errorFallback/ErrorFallback';
import { InitialLoad } from 'common/components/progress/InitialLoad';

import { ReactLazyPreload } from './lazy';

const LandscapeView = ReactLazyPreload(() => import('LandscapeViewPage/LandscapeView'));

const App: FC = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Home fetchRemainingChunks={LandscapeView.prefetch} />} />
        <Route path="/auth" element={<Authenticating />} />
        <Route
          path="/landscape"
          element={
            <Suspense fallback={<InitialLoad />}>
              <LandscapeView />
            </Suspense>
          }
        />
        <Route path="/term-of-use" element={<DisplayTerms />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </ErrorBoundary>
);

export default App;
