import React from 'react';
import { useAtom } from 'jotai';
import { styled } from 'stitches.config';

import * as SwitchPrimitive from '@radix-ui/react-switch';

import { allPeriodsSelected } from 'models/queryState/atoms';

/**
 * 検索対象データの記事リリース年を、全期間で指定するチェックボックスです。
 */

// Styles
const Switch = styled(SwitchPrimitive.Root, {
  all: 'unset',
  width: '36px',
  height: '14px',
  boxSizing: 'border-box',
  userSelect: 'none',

  // Reset
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  lineHeight: '1',
  margin: '0',
  outline: 'none',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  backgroundColor: '#97999b',
  borderRadius: '$pill',
  position: 'relative',
  '&:focus': {
    boxShadow: '0 0 0 1px #00a3e0',
  },
  '&[data-state="checked"]': {
    backgroundColor: '#046c38',
    '&:focus': {
      boxShadow: '0 0 1px 1px #00a3e0',
    },
  },
});

const Thumb = styled(SwitchPrimitive.Thumb, {
  position: 'absolute',
  left: -5,
  width: '22px',
  height: '22px',
  backgroundColor: '#d0d0ce',
  borderRadius: '$round',
  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 1px 2px;',
  transition: 'transform 100ms cubic-bezier(0.22, 1, 0.36, 1)',
  transform: 'translateX(1px)',
  willChange: 'transform',
  '&[data-state="checked"]': {
    backgroundColor: '$AccessibleGreen',
    transform: 'translateX(23px)',
  },
  '&:hover': {
    filter: 'brightness(0.9)',
    cursor: 'pointer',
  },
});

const Flex = styled('form', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
});

const Label = styled('label', {
  color: '$AnnotationText',
  fontFamily: '$Noto',
  fontSize: '14px',
  userSelect: 'none',
});

export const AllRangeSwitch: React.FC = () => {
  // States and Behaviors
  const [checkedAll, setCheckedAll] = useAtom(allPeriodsSelected);

  const handleChange = () => {
    setCheckedAll(!checkedAll);
  };

  // DOM
  return (
    <Flex css={{ justifySelf: 'end' }}>
      <Label htmlFor="switch" css={{ marginRight: '1.3em' }}>
        <Label css={{ fontSize: '12px' }}>（2016年以前を含む）</Label>全期間
      </Label>
      <Switch id="switch" defaultChecked={false} onCheckedChange={handleChange} checked={checkedAll}>
        <Thumb />
      </Switch>
    </Flex>
  );
};
