/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useAtom } from 'jotai';

import { useDebounce } from 'common/hooks/useDebounce';
import { useWindowDimensions } from 'common/hooks/useWindowDimensions';
import { clientWindowSize } from 'models/mapViewPortState/atom';

export const MapResizer: React.FC = () => {
  const [size, setSize] = useAtom(clientWindowSize);
  const useWindowSize = useWindowDimensions();

  const sizeRealTime = useWindowSize();
  const debouncedSize = useDebounce({ value: sizeRealTime, delay: 500 });

  React.useEffect(() => {
    if (debouncedSize.width !== size.width || debouncedSize.height !== size.height) {
      setSize(debouncedSize);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSize]);

  return null;
};
