import React from 'react';
import { styled, css } from 'stitches.config';
import { useAtomValue } from 'jotai';

import { headerHeightAtom } from 'models/mapViewPortState/atom';

import backgroundImg from 'assets/home_background.webp';
import { globalConst } from 'common/constant/GlobalConstant';
import { countUpVisits } from 'common/hooks/useSurvey';

import { Layout } from 'common/components/layout/Layout';
import { Footer } from 'common/components/layout/Footer';
import { FirstView } from './firstView/FirstView';
import { ScrollButton } from './ScrollButton';
import { CallToAction } from './callToAction/CallToAction';
import { ServiceFeatures } from './serviceFeatures/ServiceFeatures';

/**
 * アプリケーションのトップページです。
 * 各コンポーネントを統合し、全体のレイアウトを調整します。
 */

type Props = {
  fetchRemainingChunks: () => void;
};

export const Home: React.FC<Props> = ({ fetchRemainingChunks }) => {
  // State and Behaviors
  const marginForHeader = useAtomValue(headerHeightAtom);
  countUpVisits();

  // DOM
  return (
    <Layout
      title={`Home - ${globalConst.serviceName} Deloitte Japan`}
      description={`Home | ${globalConst.description}`}
    >
      <CoveredBackgroundImage>
        <MainVisualSection id="main-section" css={{ marginTop: '8dvh' }}>
          <FirstView actionJustBeforeSearch={fetchRemainingChunks} />
          <ScrollButton linkToId="#cta-section" />
        </MainVisualSection>

        <CallToActionSection id="cta-section" css={{ paddingTop: marginForHeader }}>
          <CallToAction callTo="#main-section" />
        </CallToActionSection>
      </CoveredBackgroundImage>

      <FeaturePointsSection id="feature-section" css={{ marginTop: '5dvh' }}>
        <ServiceFeatures />
      </FeaturePointsSection>

      <FooterSection>
        <Footer />
      </FooterSection>
    </Layout>
  );
};

// Styles
const CoveredBackgroundImage = styled('div', {
  width: '100dvw',
  display: 'grid',
  placeItems: 'center',
  fontFamily: '$Noto',
  backgroundImage: `url(${backgroundImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const widthRapper = css({
  width: 'min(1250px, 90dvw)',
  margin: 'auto',
});

const section = css({
  height: '100dvh',
});

const MainVisualSection = styled('section', section, widthRapper, {
  display: 'Grid',
  justifyItems: 'center',
  alignContent: 'center',
});

const CallToActionSection = styled('section', section, widthRapper, {
  display: 'Grid',
  placeItems: 'center',
});

const FeaturePointsSection = styled('section', section, widthRapper, {
  display: 'grid',
  placeItems: 'center',
  paddingBlock: '2em',
  height: 'auto',
  '@md': {
    height: '100dvh',
  },
});

const FooterSection = styled('div', {
  width: '100dvw',
  background: 'white',
});
