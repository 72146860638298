import React from 'react';
import { styled } from 'stitches.config';

type Props = {
  name: string;
};

export const UserName: React.FC<Props> = ({ name }) => (
  <Button href={process.env.REACT_APP_FAMBASE_UI_URL} target="_blank" rel="noopener noreferrer">
    {name} さん
  </Button>
);

// Styles
const Button = styled('a', {
  all: 'unset',
  fontFamily: '$Noto',
  fontSize: 14,
  border: 'none',
  color: 'white',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
});
