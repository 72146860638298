import React, { useEffect } from 'react';
import { styled } from 'stitches.config';

import { TermsText } from 'common/components/termsArea/TermsText';
import { Layout } from 'common/components/layout/Layout';
import { globalConst } from 'common/constant/GlobalConstant';

// DOM
export const DisplayTerms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout title={`利用規約 - ${globalConst.serviceName}`} description={`利用規約 | ${globalConst.description}`}>
      <BackGround>
        <Section>
          <TermsText />
        </Section>
      </BackGround>
    </Layout>
  );
};

// Styles
const BackGround = styled('div', {
  background: 'white',
  padding: '3em 1em',
});

const Section = styled('section', {
  paddingTop: '20dvh',
});
