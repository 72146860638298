import React from 'react';
import { styled } from 'stitches.config';

/**
 * entire form
 */
type SearchComposition = {
  Input: typeof Input;
  Submit: typeof Submit;
};
type Props = {
  children: React.ReactNode[];
  onSubmit: () => void;
  validationMessage: React.ReactNode;
  maxWidth: string;
};

export const Search: React.FC<Props> & SearchComposition = ({ children, onSubmit, validationMessage, maxWidth }) => (
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  <Form action="#" onSubmit={onSubmit} css={{ width: `min(${maxWidth}, 90dvw)` }}>
    {validationMessage}
    <SearchBox>{children}</SearchBox>
  </Form>
);

const Form = styled('form', {
  width: '100%',
});

const SearchBox = styled('div', {
  position: 'relative',
  display: 'flex',
  borderRadius: '6px',
  boxShadow: `-1px 1px 4px rgba(0,0,0,0.25)`,
  zIndex: '$2',
});

/**
 * input field
 */
type InputProps = JSX.IntrinsicElements['input'];
const Input = React.forwardRef<HTMLInputElement, InputProps>(({ ...props }, forwardedRef) => (
  <StyledInput {...props} ref={forwardedRef} />
));

const StyledInput = styled('input', {
  flex: 5,
  fontSize: '16px',
  border: '2px solid white',
  borderRadius: '6px 0 0 6px',
  padding: '8px 5px 8px 15px',
  background: 'white',
  '&:hover': {
    border: '2px solid $AccessibleGreen',
  },
  '&:focus': {
    outline: 0,
    border: '2px solid $AccessibleGreen',
  },
});

/**
 * submit button
 */
const Submit = React.forwardRef<HTMLInputElement, InputProps>(({ ...props }, forwardedRef) => (
  <StyledSubmit {...props} ref={forwardedRef} />
));

const BRILLIANT_SUBMIT_CD = '#9BD52E';
const StyledSubmit = styled('input', {
  padding: '0.5em 1em',
  border: 'none',
  borderRadius: '0 6px 6px 0',
  backgroundColor: '$AccessibleGreen',
  color: 'white',
  fontFamily: '$OpenSans',
  fontWeight: '$500',
  fontSize: 'clamp(0.84375rem, 0.7866rem + 0.2439vw, 1rem)',
  letterSpacing: 0.3,
  cursor: 'pointer',
  transition: 'background-color .3s ease-in-out',
  '&:hover': {
    backgroundColor: BRILLIANT_SUBMIT_CD,
  },
  '&:focus': {
    backgroundColor: BRILLIANT_SUBMIT_CD,
    outline: 'none',
  },
});

Search.Input = Input;
Search.Submit = Submit;
