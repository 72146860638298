import { range } from 'common/lib/utills';

const current = new Date().getFullYear();
const minYear = () => current - 5;
export const PERIOD_RANGE = [...range(minYear(), current)] as const;
export type PeriodYear = (typeof PERIOD_RANGE)[number];

export const DATA_SOURCE = {
  PRTIMES: 'prtimes_page',
  MA_ONLNE: 'maonline_quick_announcement_page',
} as const;
export type DataSource = (typeof DATA_SOURCE)[keyof typeof DATA_SOURCE];
