import { atom } from 'jotai';
import { min, max } from 'd3-array';
import { scaleLinear } from 'd3-scale';

import { coordinates, nodeIds } from 'models/serverData/atoms';

import type { D3Scale } from 'common/types/Chart';

import { scaleWithResults, expand } from './helper';

/**
 * クライアントブラウザの実際のサイズです。
 */
export const clientWindowSize = atom({
  width: window.innerWidth,
  height: window.innerHeight,
});

/**
 * マップのサイズは、検索結果件数に応じたスケールで決定します。
 */
export const scaledMapSize = atom((get) => {
  const windowSize = get(clientWindowSize);
  const numOfResults = get(nodeIds).length;

  const scale = scaleWithResults(numOfResults);
  const expandW = expand(1400);
  const expandH = expand(800);

  return {
    width: scale(expandW(windowSize.width)),
    height: scale(expandH(windowSize.height)),
  };
});

export const scaleXState = atom<D3Scale>((get) => {
  const coordinatesX = get(coordinates).map((o) => o[0]);
  const windowSizeX = get(scaledMapSize).width;

  return scaleLinear()
    .domain([(min(coordinatesX) || 0) - 1, (max(coordinatesX) || 0) + 1])
    .rangeRound([0, windowSizeX]);
});
export const scaleYState = atom<D3Scale>((get) => {
  const coordinatesY = get(coordinates).map((o) => o[1]);
  const windowSizeY = get(scaledMapSize).height;

  return scaleLinear()
    .domain([(min(coordinatesY) || 0) - 1, (max(coordinatesY) || 0) + 1])
    .rangeRound([windowSizeY, 0]);
});

export const visibleWordCloud = atom(true);

/**
 * マップ上の、上方向のマージンとして必要な、ヘッダーの高さです。
 */
export const headerHeightAtom = atom(0);
