export const globalConst = {
  serviceName: 'Trend Insights',
  purposeOfService: 'Make unexpected discoveries',
  description:
    'ビジネストレンドが一目でつかめる情報検索AI。ビジネストレンドを立体的視点によりビジュアライズし、効率的な情報収集と予想外の繋がりの発見をサポートします。月間6,000万PVを誇るPR TIMESのデータがキーワードを入れるだけで検索可能。',
  contactUsEmailAddress: 'info.trendinsights@tohmatsu.co.jp',
} as const;

export const ExternalLinks = {
  PrivacyPolicy: 'https://www2.deloitte.com/jp/ja/footerlinks1/privacy/privacy-for-other-sites.html',
  CookieNotices: 'https://www2.deloitte.com/jp/ja/legal/cookies/cookies-notice-for-other-sites.html',
  GroupInformation: 'https://www2.deloitte.com/jp/ja/pages/about-deloitte/articles/about-deloitte.html',
  SISTER_SITES: [
    {
      name: 'MBI',
      url: 'https://lp.mbi.fa-biz.deloitte.jp/',
    },
    {
      name: globalConst.serviceName,
      url: '#',
    },
    {
      name: 'M&A プラス',
      url: 'https://ma-plus.fa-pfm.deloitte.jp/',
    },
    {
      name: 'デロイト トーマツ アカデミー',
      url: 'https://academy.ma-plus.com/',
    },
    {
      name: 'FA Portal',
      url: 'https://faportal.deloitte.jp/',
    },
    {
      name: 'シナリオプランニング',
      url: 'https://lp.scenario-planning.fa-pfm.deloitte.jp/',
    },
  ],
};
