import React from 'react';
import { styled } from 'stitches.config';

type FaMateRegistrationURL = string;
type LoginProcess = () => Promise<void>;

type Props = {
  label: string;
  backgroundcolor: 'black' | 'green';
  linkTo: FaMateRegistrationURL | LoginProcess;
};

export const LoginButton: React.FC<Props> = ({ label, backgroundcolor, linkTo }) => {
  const isLink = typeof linkTo === 'string';

  const buttonProps = isLink ? { href: linkTo, target: '_blank', rel: 'noopener noreferrer' } : { onClick: linkTo };

  return (
    <Button background={backgroundcolor} {...buttonProps}>
      {label}
    </Button>
  );
};

// Styles
const Button = styled('a', {
  all: 'unset',
  fontFamily: '$Noto',
  fontSize: 14,
  borderRadius: 4,
  padding: '7px 1rem 7px 1rem',
  whiteSpace: 'nowrap',
  variants: {
    background: {
      black: {
        color: '#92bb44',
        backgroundColor: '#151e05',
        border: 'solid 1px #92bb44',
      },
      green: {
        color: '#151e05',
        backgroundColor: '$DeloitteGreen',
        border: 'solid 1px $DeloitteGreen',
      },
    },
  },
  cursor: 'pointer',
  '&:hover': {
    filter: 'brightness(80%)',
  },
});
