import React from 'react';
import { styled, keyframes } from 'stitches.config';

import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

import { handleLoginClicked } from '../helperLogic';
import { REGISTER_LINK } from '../constant';

type Props = {
  isOpen: boolean;
  openChange: React.Dispatch<React.SetStateAction<boolean>>;
  reLogin?: boolean;
};

export const LoginModal: React.FC<Props> = ({ isOpen, openChange, reLogin = false }) => (
  <Dialog.Root open={isOpen} onOpenChange={openChange}>
    <Dialog.Portal>
      <DialogOverlay />
      <DialogContent>
        <LoginContainer>
          <Header>SIGNUP / LOGIN</Header>
          {reLogin ? (
            <Describe>セッションの有効期間が切れました。お手数ですがもう一度ログインしてください。</Describe>
          ) : (
            <InitialLogin />
          )}
          <ButtonLogin
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleLoginClicked}
          >
            ログイン
          </ButtonLogin>
        </LoginContainer>
        <Dialog.Close asChild>
          <IconButton aria-label="Close">
            <Cross2Icon width="25px" height="25px" />
          </IconButton>
        </Dialog.Close>
      </DialogContent>
    </Dialog.Portal>
  </Dialog.Root>
);

const InitialLogin = () => (
  <>
    <Describe>本サービスのご利用には会員登録が必要です。</Describe>
    <ButtonRegister href={REGISTER_LINK} target="_blank" rel="noopener noreferrer">
      無料で会員登録する
    </ButtonRegister>
    <Describe>既に会員登録済みの方はこちら。</Describe>
  </>
);

// Animations
const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

// Styles
const DialogOverlay = styled(Dialog.Overlay, {
  background: 'rgba(0 0 0 / 0.5)',
  position: 'fixed',
  zIndex: '$4',
  inset: 0,
  display: 'grid',
  placeItems: 'center',
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

const DialogContent = styled(Dialog.Content, {
  display: 'grid',
  placeItems: 'center',
  backgroundColor: 'white',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  zIndex: '$max',
  width: 'min(95dvw, 450px)',
  maxHeight: '80dvh',
  padding: '3em 2em',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflowY: 'auto',
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
});

const LoginContainer = styled('div', {
  display: 'grid',
  placeItems: 'center',
  gap: '4.7dvh',
});

const Header = styled('h2', {
  fontFamily: '$OpenSans',
  fontSize: 'clamp(1.7rem, 1.0417rem + 1.8vw, 3rem)',
  fontWeight: 'bold',
  color: 'black',
  textAlign: 'center',
});

const Describe = styled('p', {
  fontFamily: '$Noto',
  fontSize: 'clamp(1rem, 0.8rem + 0.5vw, 1.2rem)',
  lineHeight: 1.4,
  color: '$gray11',
  textAlign: 'start',
  justifySelf: 'start',
  marginBottom: '-0.7em',
});

const IconButton = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 30,
  width: 30,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  position: 'absolute',
  top: 10,
  right: 12,

  '&:hover': { backgroundColor: '$gray9' },
  '&:focus': { boxShadow: `0 0 0 2px $gray8` },
});

const ButtonRegister = styled('a', {
  all: 'unset',
  backgroundColor: '$AccessibleGreen',
  color: 'white',
  fontSize: 'clamp(1.1rem, 0.6rem + 0.7vw, 1.2rem)',
  fontWeight: 'bold',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  width: 'min(180px, 90%)',
  padding: 'min(4dvw, 1em) min(5dvh, 2em)',
  boxShadow: `-1px 1px 5px rgba(0,0,0,0.3)`,
  '&:hover': {
    filter: 'brightness(80%)',
    cursor: 'pointer',
    boxShadow: 'none',
  },
});

const ButtonLogin = styled('a', {
  all: 'unset',
  backgroundColor: '#f2f2f2',
  color: '$AccessibleGreen',
  fontSize: 'clamp(1.1rem, 0.6rem + 0.7vw, 1.2rem)',
  fontWeight: 'bold',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  width: 'min(180px, 90%)',
  padding: 'min(4dvw, 1em) min(5dvh, 2em)',
  boxShadow: `-1px 1px 5px rgba(0,0,0,0.3)`,
  '&:hover': {
    filter: 'brightness(80%)',
    cursor: 'pointer',
    boxShadow: 'none',
  },
});
