/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { styled, keyframes } from 'stitches.config';
import { useAtomValue, useSetAtom } from 'jotai';

import { user } from 'models/user/atom';
import { queryKeyWord, queryPeriod, allPeriodsSelected } from 'models/queryState/atoms';
import { PERIOD_RANGE } from 'models/queryState/types';
import type { PeriodYear } from 'models/queryState/types';
import { countUpSearches } from 'common/hooks/useSurvey';

import { CaretDownIcon } from '@radix-ui/react-icons';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';

import { recommendWords } from './staticWords';

type Props = {
  onClick: (currentUser: any) => void;
  onHover?: () => void;
};

export const FeaturedWords: React.FC<Props> = ({ onClick, onHover }) => {
  // States and Behaviors
  const setQuery = useSetAtom(queryKeyWord);
  const setPeriod = useSetAtom(queryPeriod);
  const setSelectAll = useSetAtom(allPeriodsSelected);
  const currentUser = useAtomValue(user);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, word: string, wantUptodateResults = false) => {
    event.preventDefault();

    countUpSearches();
    setQuery(word);
    setSelectAll(false);
    if (wantUptodateResults) {
      setPeriod(latestTwoYears());
    } else {
      setPeriod(getAllRange());
    }

    onClick(currentUser);
  };

  const latestTwoYears = (range = PERIOD_RANGE) => range.slice(-2) as [number, number];
  const getAllRange = (range = PERIOD_RANGE): [PeriodYear, PeriodYear] => [range[0], range.slice(-1)[0]];

  // DOM
  return (
    <NavigationMenu>
      <NavigationMenuList onMouseEnter={onHover} onClick={onHover}>
        {recommendWords.map((words, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <NavigationMenuItem key={i}>
            <NavigationMenuTrigger>{words.phrase}</NavigationMenuTrigger>
            <NavigationMenuContent>
              <ContentList>
                {words.data.map((word) => (
                  <ListItem key={word}>
                    <NavigationMenuLink
                      title={word}
                      // implicit: i === 0は、「業界で探す」のときを表す。（ListItem の先頭）
                      onClick={(event) => handleClick(event, word, i === 0)}
                    >
                      <Button>{word}</Button>
                    </NavigationMenuLink>
                  </ListItem>
                ))}
              </ContentList>
            </NavigationMenuContent>
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>

      <ViewportPosition>
        <NavigationMenuViewport />
      </ViewportPosition>
    </NavigationMenu>
  );
};

export const FeaturedWordsPlaceholder: React.FC = () => (
  <NavigationMenu>
    <NavigationMenuList>
      {recommendWords.map((words, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <NavigationMenuItem key={i}>
          <NavigationMenuTrigger>{words.phrase}</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ContentList>
              {words.data.map((word) => (
                <ListItem key={word}>
                  <NavigationMenuLink title={word}>
                    <Button>{word}</Button>
                  </NavigationMenuLink>
                </ListItem>
              ))}
            </ContentList>
          </NavigationMenuContent>
        </NavigationMenuItem>
      ))}
    </NavigationMenuList>
  </NavigationMenu>
);

// Animations
const enterFromRight = keyframes({
  from: { transform: 'translateX(200px)', opacity: 0 },
  to: { transform: 'translateX(0)', opacity: 1 },
});

const enterFromLeft = keyframes({
  from: { transform: 'translateX(-200px)', opacity: 0 },
  to: { transform: 'translateX(0)', opacity: 1 },
});

const exitToRight = keyframes({
  from: { transform: 'translateX(0)', opacity: 1 },
  to: { transform: 'translateX(200px)', opacity: 0 },
});

const exitToLeft = keyframes({
  from: { transform: 'translateX(0)', opacity: 1 },
  to: { transform: 'translateX(-200px)', opacity: 0 },
});

const scaleIn = keyframes({
  from: { transform: 'rotateX(-30deg) scale(0.9)', opacity: 0 },
  to: { transform: 'rotateX(0deg) scale(1)', opacity: 1 },
});

const scaleOut = keyframes({
  from: { transform: 'rotateX(0deg) scale(1)', opacity: 1 },
  to: { transform: 'rotateX(-10deg) scale(0.95)', opacity: 0 },
});

// Styles
const StyledMenu = styled(NavigationMenuPrimitive.Root, {
  width: 'fit-content',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1,
});

const StyledList = styled(NavigationMenuPrimitive.List, {
  all: 'unset',
  backgroundColor: 'white',
  padding: 0,
  borderRadius: 6,
  listStyle: 'none',
  boxShadow: `-1px 1px 6px rgba(0,0,0,0.2)`,
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '80dvw',
  '& button': {
    justifyContent: 'space-between',
    gap: 5,
    paddingRight: 18,
    paddingLeft: 18,
  },
});

const itemStyles = {
  display: 'block',
  padding: '0.9em 0',
  margin: 0,
  outline: 'none',
  userSelect: 'none',
  fontWeight: 500,
  lineHeight: 1,
  borderRadius: '$1',
  fontSize: 'clamp(0.8rem, 0.7rem + 0.2778vw, 1rem)',
  color: '$AccessibleGreen',
  '&:focus': { boxShadow: `0 0 0 2px #00a3e0` },
  '&:hover': {
    backgroundColor: '$AccessibleGreen',
    color: 'white',
    '& *': {
      color: 'white',
    },
  },
};

const StyledTrigger = styled(NavigationMenuPrimitive.Trigger, {
  all: 'unset',
  ...itemStyles,
  display: 'flex',
  alignCenter: 'center',
  fontFamily: '$Noto',
  gap: 55,
  width: 'stretch',
});

const StyledCaret = styled(CaretDownIcon, {
  position: 'relative',
  color: '$AccessibleGreen',
  top: 1,
  '[data-state=open] &': { transform: 'rotate(-180deg)' },
  '@media (prefers-reduced-motion: no-preference)': {
    transition: 'transform 250ms ease',
  },
});

type ButtonProps = JSX.IntrinsicElements['button'];
const StyledTriggerWithCaret = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, forwardedRef) => (
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      <StyledCaret aria-hidden />
    </StyledTrigger>
  )
);

const StyledLink = styled(NavigationMenuPrimitive.Link, {
  ...itemStyles,
  textDecoration: 'none',
  padding: '0.5em 0',
});

const StyledContent = styled(NavigationMenuPrimitive.Content, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  '@media only screen and (min-width: 600px)': { width: 'auto' },
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '250ms',
    animationTimingFunction: 'ease',
    '&[data-motion="from-start"]': { animationName: enterFromLeft },
    '&[data-motion="from-end"]': { animationName: enterFromRight },
    '&[data-motion="to-start"]': { animationName: exitToLeft },
    '&[data-motion="to-end"]': { animationName: exitToRight },
  },
});

const StyledViewport = styled(NavigationMenuPrimitive.Viewport, {
  position: 'relative',
  transformOrigin: 'top center',
  marginTop: 10,
  backgroundColor: '#F5F5F5',
  borderRadius: 6,
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  height: 'var(--radix-navigation-menu-viewport-height)',
  width: '24em',
  '@media only screen and (min-width: 600px)': {
    width: 'var(--radix-navigation-menu-viewport-width)',
  },
  '@media (prefers-reduced-motion: no-preference)': {
    transition: 'width, height, 300ms ease',
    '&[data-state="open"]': { animation: `${scaleIn} 200ms ease` },
    '&[data-state="closed"]': { animation: `${scaleOut} 200ms ease` },
  },
});

const NavigationMenuItem = styled(NavigationMenuPrimitive.Item, {
  flex: '1 1 auto',
});

// Exports
const NavigationMenu = StyledMenu;
const NavigationMenuList = StyledList;
// const NavigationMenuItem = NavigationMenuPrimitive.Item;
const NavigationMenuTrigger = StyledTriggerWithCaret;
const NavigationMenuLink = StyledLink;
const NavigationMenuContent = StyledContent;
const NavigationMenuViewport = StyledViewport;

const ContentList = styled('ul', {
  padding: '10px 0 10px 10px',
  margin: 0,
  columnGap: 10,
  listStyle: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  width: '85dvw',

  '@media only screen and (min-width: 600px)': {
    padding: 22,
    width: '22em',
    gridAutoFlow: 'column',
    gridTemplateRows: 'repeat(3, 1fr)',
  },
  '@media only screen and (min-width: 1300px)': {
    width: '28em',
    gridAutoFlow: 'column',
    gridTemplateRows: 'repeat(3, 1fr)',
  },
});

const ListItem = styled('li', {
  fontFamily: '$Noto',
  listStyle: 'none',
});

const Button = styled('button', {
  width: '100%',
  fontFamily: '$Noto',
  fontSize: 'clamp(0.6875rem, 0.6134rem + 0.3704vw, 0.9375rem)',
  border: 'none',
  background: 'transparent',
  color: 'inherit',
  padding: '0 0.5em',
  cursor: 'pointer',
});

const ViewportPosition = styled('div', {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  top: '100%',
  left: 0,
  perspective: '2000px',
});
