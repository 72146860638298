import React from 'react';
import { styled } from 'stitches.config';

import { ReactComponent as DownIcon } from 'assets/down.svg';

type Props = {
  linkToId: string;
};

// DOM
export const ScrollButton: React.FC<Props> = ({ linkToId }) => (
  <Button href={linkToId} aria-label="Read more description">
    <Icon />
  </Button>
);

// Styles
const Button = styled('a', {
  position: 'absolute',
  bottom: '4dvh',
  borderRadius: '$round',
  '@sm': { bottom: '9dvh' },
  left: '50%',
  transform: 'translate(-50%, 0)',
  filter: 'drop-shadow(0 0 3.5px rgba(0,0,0,0.25))',
  '&:hover': {
    filter: 'brightness(95%) ',
    cursor: 'pointer',
  },
});
const Icon = styled(DownIcon, {
  color: '$AccessibleGreen',
});
