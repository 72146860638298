import { FC } from 'react';
import { styled } from 'stitches.config';

import { InfoCircledIcon } from '@radix-ui/react-icons';

type Props = {
  message: string | undefined;
};

// Styles
const Message = styled('p', {
  position: 'absolute',
  fontFamily: '$Noto',
  fontSize: '13px',
  margin: '-1.2em 0 0 1em',
  color: '$red9',
  display: 'flex',
  gap: '$1',
});

// DOM
export const ValidationMessage: FC<Props> = ({ message }) => {
  if (!message) return null;
  return (
    <Message>
      <InfoCircledIcon />
      {message}
    </Message>
  );
};

// Parameter
export const validationRules = {
  required: true,
  maxLength: { value: 25, message: `文字数が多すぎるようです` },
  // 漢字１文字 または 英字/ひらがな/カタカナ/漢字を1文字以上含み２文字以上
  pattern: {
    value: /[\u4E00-\u9FFF]{1}|^(?=.*?[a-zA-Z\u3041-\u3096\u30A1-\u30FA\u4E00-\u9FAF]).{2,}$/,
    message: '単語を入力してください',
  },
};
