import { FC, ReactNode } from 'react';
import { styled } from 'stitches.config';

type Props = {
  children: ReactNode;
};

// DOM
export const WidthContainer: FC<Props> = ({ children }) => <LimitWidth>{children}</LimitWidth>;

// Styles
const LimitWidth = styled('div', {
  width: '100%',
  maxWidth: '1921px',
  padding: '0 30px',
});
