import { fullAxios } from 'common/lib/authAPI';

export const handleLoginClicked = async () => {
  try {
    const res = await fullAxios.get(
      `${process.env.REACT_APP_TRENDINSIGHTS_ORIGIN}/api/open-login-session?redirect_uri=${process.env.REACT_APP_ORIGIN}/auth`,
      { withCredentials: true }
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    window.location.href = res.data.location as string;
  } catch (error) {
    console.error(error);
  }
};

export const tokenInstopectionLocaly = () => {
  const token = localStorage.getItem('accessToken') as string;

  if (!token) {
    return null;
  }

  // アクセストークンをピリオドで分割し、ペイロード部分を取得
  const base64Url = token.split('.')[1];
  // Base64URL形式を通常のBase64形式に変換
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  // Base64をデコードしてJSON文字列を取得し、パースする
  const payload = JSON.parse(window.atob(base64));

  return payload as TokenPayload;
};

type TokenPayload = {
  azp?: string; // クライアント
  exp?: number; // 有効期限
  iat?: number; // 発行された時刻
  sub?: string; // sub（通常はユーザーID）
  aud?: string; // 対象者
  iss?: string; // 発行者
};
