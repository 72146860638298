import React from 'react';
import { styled } from 'stitches.config';

import { ExclamationTriangleIcon } from '@radix-ui/react-icons';

type Props = {
  message?: string;
  errorCode: string;
  fontSize?: string;
};

export const PartialError: React.FC<Props> = ({
  errorCode,
  message = `エラー: ${errorCode} 問題が永続する場合お問合せください。`,
  fontSize = '0.5rem',
}) => (
  <MessageArea css={{ fontSize }}>
    <ExclamationTriangleIcon color="red" />
    {message}
  </MessageArea>
);

const MessageArea = styled('div', {
  backgroundColor: 'rgba(245, 191, 202, 0.9)',
  color: 'black',
  padding: '0.4em',
  textAlign: 'center',
  margin: '0.1rem',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5em',
});
