/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getCookieValue } from 'common/lib/utills';

const getBaseAxios = (withCsrfToken = false, withToken = true) => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (config) => {
      const modifiedConfig = { ...config };
      modifiedConfig.headers['Content-Type'] = 'application/json';
      if (withCsrfToken) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const csrftoken = getCookieValue('csrftoken');
        if (csrftoken) {
          modifiedConfig.headers['X-CSRFToken'] = csrftoken;
        }
      }
      if (withToken) {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          modifiedConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
      return modifiedConfig;
    },
    (error) => Promise.reject(error)
  );

  // レスポンスインターセプターを設定
  axiosInstance.interceptors.response.use(
    (response) =>
      // 通常のレスポンスをそのまま返す
      response,
    async (error: AxiosError) => {
      interface CustomAxiosRequestConfig extends AxiosRequestConfig {
        hasRetried?: boolean | undefined;
      }
      const originalRequest = error.config as CustomAxiosRequestConfig;

      // 400系のエラーの場合、トークンをリフレッシュしてリトライ
      const refreshToken = localStorage.getItem('refreshToken');
      if (
        error.response &&
        refreshToken &&
        error.response.status >= 400 &&
        error.response.status < 500 &&
        !originalRequest.hasRetried
      ) {
        originalRequest.hasRetried = true;

        try {
          const response = await axios({
            // TODO: API実装後にここの型を定義
            url: `${process.env.REACT_APP_TRENDINSIGHTS_ORIGIN}/api/reset-token`,
            method: 'post',
            data: {
              refresh_token: refreshToken,
            },
          });

          // 新しいアクセストークンをローカルストレージに保存
          localStorage.setItem('accessToken', response.data.access_token);
          localStorage.setItem('refreshToken', response.data.refresh_token);

          // 新しいトークンでリクエストをリトライ
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          originalRequest.headers!.Authorization = `Bearer ${response.data.access_token}`;
          return await axiosInstance(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export const fullAxios = getBaseAxios(true, true);

export const coreAxios = getBaseAxios(false, true);

type IndustryType = {
  industry_type_code: string;
  name: string;
};

type JobType = {
  job_type_code: string;
  name: string;
};

type JobRole = {
  job_role_code: string;
  name: string;
};

type Policy = {
  id: number;
  name: string;
};

type BusinessAsset = {
  id: number;
  asset_id: string;
  name: string;
  short_name: string;
  url: string;
};

export type FaMateProfile = {
  first_name: string;
  last_name: string;
  first_name_kana: string | null;
  last_name_kana: string | null;
  residence_location: string;
  affiliated_corporation: string;
  company_website_url: string;
  company_address: string;
  id: string;
  email: string;
  industry_type: IndustryType;
  job_type: JobType;
  job_role: JobRole;
  is_authenticated: boolean;
  is_admin: boolean;
  registration_status: number;
  registration_status_label: string;
  has_corporate_contract: boolean;
  profile_last_updated_at: string;
  access_management_editable: boolean;
  attached_policies: Policy[];
  business_assets: BusinessAsset[];
  admin_business_assets: BusinessAsset[];
  admin_business_assets_restriction_enabled: boolean;
};
