import React from 'react';
import { styled } from 'stitches.config';

import { ReactComponent as Point1 } from 'assets/point1.svg';
import { ReactComponent as Point2 } from 'assets/point2.svg';
import { ReactComponent as Point3 } from 'assets/point3.svg';

import { FeatureCard } from './FeatureCard';

/**
 * サービスの特徴を説明するセクションです。
 */

// DOM
export const ServiceFeatures: React.FC = () => (
  <Container>
    <SectionHead>SERVICE</SectionHead>
    <SectionLead>サービスの特徴</SectionLead>
    <Features>
      <FeatureCard
        number={1}
        icon={<Point1 width="1.2em" />}
        title="ホットなキーワードがわかる"
        description={[
          `気になるキーワードから検索するのが基本の使い方。`,
          `でもそれだけではありません。キーワードとつながる今まさにホットな`,
          `トレンドが提案され、課題の解決に役立ちます。`,
        ].join('')}
      />
      <FeatureCard
        number={2}
        icon={<Point2 width="1.2em" />}
        title="トレンドの全体像をビジュアルでつかめる"
        description={[
          `情報間の相対的な関係性やそれらが形成するクラスターを理解し、`,
          `その中でもホットな領域を瞬時に特定することができます。`,
        ].join('')}
      />
      <FeatureCard
        number={3}
        icon={<Point3 width="1.2em" />}
        title="個々の情報解像度を上げる"
        description={[
          `時系列による検索に加え、情報発信源の企業ごとや`,
          `意味が近い情報同士を整理するだけでなく、発信情報を`,
          `瞬時に辿ることもできます。`,
        ].join('')}
      />
    </Features>
  </Container>
);

// styles
const Container = styled('div', {
  display: 'grid',
  placeItems: 'center',
  gap: '1em',
  width: '100%',
});

const SectionHead = styled('h3', {
  fontSize: '$head2',
  fontFamily: '$OpenSans',
  fontWeight: '$600',
  letterSpacing: 2.5,
});

const SectionLead = styled('p', {
  fontSize: '$body2',
  fontFamily: '$Noto',
  fontWeight: '$500',
  color: '$AccessibleGreen',
});

const Features = styled('div', {
  width: 'min(95%, 1050px)',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '2em',
  '@sm': {
    marginBlock: '3em',
  },
});
