import { FC, useState, useEffect } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { styled } from 'stitches.config';

import * as SliderPrimitive from '@radix-ui/react-slider';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { queryPeriod, allPeriodsSelected } from 'models/queryState/atoms';
import { PERIOD_RANGE } from 'models/queryState/types';
import type { PeriodYear } from 'models/queryState/types';
import { useDebounce } from 'common/hooks/useDebounce';

type Props = {
  debounce?: boolean;
};

// Styles
const Form = styled('form', {
  display: 'grid',
  alignItems: 'center',
  gap: '7px',
  width: '100%',
});

const Slider = styled(SliderPrimitive.Root, {
  width: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
  touchAction: 'none',
  '&[data-orientation="horizontal"]': {
    height: 22,
  },

  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    width: 22,
    height: 100,
  },
});

const Track = styled(SliderPrimitive.Track, {
  backgroundColor: '$Disabled',
  position: 'relative',
  flexGrow: 0.95,
  alignSelf: 'center',
  borderRadius: '20px',
  '&[data-orientation="horizontal"]': { height: '7px' },
  '&[data-orientation="vertical"]': { width: 3 },
  '&[data-disabled]': {
    backgroundColor: '$Disabled',
  },
});

const Range = styled(SliderPrimitive.Range, {
  position: 'absolute',
  backgroundColor: '$AccessibleGreen',
  borderRadius: '9999px',
  height: '100%',
  '&[data-disabled]': {
    backgroundColor: '$Disabled',
  },
});

const labelBase = {
  textAlign: 'center',
  fontSize: '10pt',
  fontFamily: 'system-ui',
  border: '1px solid #FFFFFF',
  borderRadius: '20px',
  padding: '0.2em 0.5em',
  boxShadow: '-1px 1px 3px #00000047',
  backgroundColor: '$AccessibleGreen',
  color: 'white',
};

const Thumb = styled(SliderPrimitive.Thumb, {
  all: 'unset',
  display: 'block',
  width: '22px',
  height: '22px',
  background: 'rgba(255, 255, 255, 0.6)',
  boxShadow: '-1px 1px 6px #00000029',
  borderRadius: '$round',
  '&:focus': { boxShadow: `0 0 0 1px #00a3e0` },
  '&:hover': {
    filter: 'brightness(0.9)',
    cursor: 'pointer',
  },
  '&::before': {
    content: `''`,
    display: 'block',
    background: '$AccessibleGreen',
    boxShadow: '-1px 1px 6px #00000029',
    position: 'absolute',
    top: '3px',
    left: '3px',
    width: '16px',
    height: '16px',
    borderRadius: 'inherit',
  },
  '&::after': {
    position: 'relative',
    top: 29.5,
    right: 12,
    ...labelBase,
  },
  '&[data-disabled]': {
    backgroundColor: '#d0d0ce',
    boxShadow: 'none',
    pointerEvents: 'none',
    '&::before': {
      content: `''`,
      display: 'block',
      background: '$Disabled',
      position: 'absolute',
      top: '3px',
      left: '3px',
      width: '16px',
      height: '16px',
      borderRadius: 'inherit',
    },
    '&::after': {
      background: '$Disabled',
      color: '#d0d0ce',
      boxShadow: 'none',
    },
  },
});

const LabelArea = styled('div', {
  marginX: '-0.7em',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Label = styled('div', {
  ...labelBase,
  variants: {
    color: {
      active: {
        backgroundColor: '$AccessibleGreen',
        color: 'white',
      },
      disabled: {
        backgroundColor: '$Disabled',
        color: '#d0d0ce',
        boxShadow: 'none',
      },
    },
  },
  defaultVariants: {
    color: 'active',
  },
});

// Helpers
const minYear = Math.min(...PERIOD_RANGE);
const maxYear = Math.max(...PERIOD_RANGE);

export const PeriodRangeSlider: FC<Props> = ({ debounce = false }) => {
  // State and Behaviors
  const disabled = useAtomValue(allPeriodsSelected);

  const [queryYears, setQueryYears] = useAtom(queryPeriod);
  const [localYear, setLocalYear] = useState<[PeriodYear, PeriodYear]>(queryYears);

  const delay = debounce ? 1000 : 0;
  const debouncedYear = useDebounce<typeof localYear>({ value: localYear, delay });

  useEffect(() => {
    if (localYear && debouncedYear) {
      setQueryYears(debouncedYear);
    }
    return () => {};
  }, [debouncedYear, localYear, setQueryYears]);

  const handleChange = (newValue: [PeriodYear, PeriodYear]) => {
    setLocalYear(newValue);
  };

  // DOM
  return (
    <PopoverPrimitive.Root>
      <Form>
        <Slider
          defaultValue={localYear}
          min={minYear}
          max={maxYear}
          step={1}
          value={localYear}
          onValueChange={handleChange}
          disabled={disabled}
        >
          <Track>
            <Range />
          </Track>
          {localYear.map((year, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Thumb key={i} css={{ '&::after': { content: `${year}` } }} />
          ))}
        </Slider>
        <LabelArea css={{ marginBlockStart: -1 }}>
          <Label color="disabled" css={{ visibility: minYear === Math.min(...localYear) ? 'hidden' : 'visible' }}>
            {minYear}
          </Label>
          <Label color="disabled" css={{ visibility: maxYear === Math.max(...localYear) ? 'hidden' : 'visible' }}>
            {maxYear}
          </Label>
        </LabelArea>
      </Form>
    </PopoverPrimitive.Root>
  );
};
