import React, { FC, Suspense, useState } from 'react';
import { styled } from 'stitches.config';
import { useNavigate } from 'react-router-dom';

import { FeaturedWords, FeaturedWordsPlaceholder } from 'common/components/featuredWords/FeaturedWords';
import { SearchWithPopper } from 'common/components/search/SearchWithPopper';
import { Search } from 'common/components/search/Search';
import { PeriodController } from 'common//components/periodController/PeriodController';
import { globalConst } from 'common/constant/GlobalConstant';
import { LoginModal } from 'common/components/login/loginMordal/LoginModal';
import { FaMateProfile } from 'common/lib/authAPI';

import { TermsModal } from './TermsModal';
import { SpeechBubble } from './SpeechBubble';

/**
 * ファーストビューのメインコンポーネントです。
 */

type Props = {
  actionJustBeforeSearch: () => void;
};

export const HeroContent: FC<Props> = ({ actionJustBeforeSearch }) => (
  <Grid>
    <TagLine>
      <ServiceName>{globalConst.serviceName}</ServiceName>
      <Concept>
        ビジネストレンドが一目でつかめる<Paint>情報検索AI</Paint>
      </Concept>
    </TagLine>
    <Suspense fallback={<Placeholder />}>
      <InteractiveParts actionJustBeforeSearch={actionJustBeforeSearch} />
    </Suspense>
  </Grid>
);

const InteractiveParts: FC<Props> = ({ actionJustBeforeSearch }) => {
  // State and Behaviors

  const [showTermModal, setShowTermModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const navigate = useNavigate();
  const linkTo = (currentUser: FaMateProfile | null) => {
    if (!currentUser) {
      setShowLoginModal(true);
      return;
    }

    if (!hasAgreedToTerms()) {
      setShowTermModal(true);
    } else {
      navigate('/landscape');
    }
  };

  const hasAgreedToTerms = () => {
    const agreed = localStorage.getItem('agreedToTerms');
    return agreed === 'true';
  };

  const handleModalConfirmation = () => {
    localStorage.setItem('agreedToTerms', 'true');
    setShowTermModal(false);
    navigate('/landscape');
  };

  return (
    <>
      <SpeechBubble offset={6} onclick={() => setShowLoginModal(true)}>
        <InteractiveElement>
          <SearchWithPopper
            maxWidth="min(540px, 80dvw)"
            placeHolder={enoughWidth() ? 'まずは、関心のある単語をご入力下さい' : '関心のある単語を入力'}
            onSubmit={linkTo}
            onFocus={actionJustBeforeSearch}
            popMessage="検索期間を変更しますか？"
            popContent={<PeriodController />}
          />
          <FeaturedWords onClick={linkTo} onHover={actionJustBeforeSearch} />
        </InteractiveElement>
      </SpeechBubble>

      <LoginModal isOpen={showLoginModal} openChange={setShowLoginModal} />
      <TermsModal isOpen={showTermModal} openChange={setShowTermModal} onConfirm={handleModalConfirmation} />
    </>
  );
};

// helper
const enoughWidth = () => window.matchMedia('(min-width: 500px)').matches;

// Styles
const Grid = styled('div', {
  display: 'Grid',
  justifyItems: 'start',
  alignContent: 'center',
  gap: '4em',
});

const TagLine = styled('div', {
  display: 'Grid',
  justifyItems: 'start',
  alignItems: 'start',
  gap: '1em',
  width: 'min(600px, 85dvw)',
});

const ServiceName = styled('div', {
  fontSize: 'clamp(1.25rem, 0.7292rem + 1.6667vw, 1.875rem)',
  fontFamily: '$OpenSans',
  fontWeight: '$400',
  color: '#046c38',
  marginInline: '0.2em',
});

const Concept = styled('div', {
  fontSize: 'clamp(2.1875rem, 1.4063rem + 2.5vw, 3.125rem)',
  fontFamily: '$NotoSans',
  fontWeight: '$600',
  color: 'rgb(42, 42, 42)',
});

/*
const gradient = keyframes({
  '0%': { backgroundPosition: '0% 50%' },
  '50%': { backgroundPosition: '100% 50%' },
  '100%': { backgroundPosition: '0% 50%' },
});
*/

const Paint = styled('span', {
  fontWeight: 'bolder',
  // color: 'transparent',
  color: '$AccessibleGreen',
  // background: 'linear-gradient(45deg, #86BC25 5%, #0EDB00 33%, #26890D 66%)',
  display: 'inline-block',
  textShadow: '1px 1px 1px rgba(0,0,0,0.3)',
  // animation: `${gradient} 8s ease infinite`,
  backgroundSize: '200% 100%',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
});

const InteractiveElement = styled('div', {
  display: 'grid',
  gap: '1.5em',
  justifyItems: 'center',
  alignItems: 'start',
  marginInline: '0.2em',
});

// Skeleton
const SearchForm = styled(Search, {});
const Anchor = styled('div', {});
const Input = styled(Search.Input, {});
const Submit = styled(Search.Submit, {});

const Placeholder: React.FC = () => (
  <InteractiveElement>
    <SearchForm validationMessage="" onSubmit={() => {}} maxWidth="min(540px, 80dvw)">
      <Anchor css={{ flex: 5 }}>
        <Input placeholder="now loading..." css={{ width: '100%' }} />
      </Anchor>
      <Submit value="Explore!" type="submit" css={{ flex: 1.5, '@sm': { flex: 2 } }} />
    </SearchForm>
    <FeaturedWordsPlaceholder />
  </InteractiveElement>
);
