import React from 'react';
import { styled } from 'stitches.config';

type Notes = {
  essentials: string;
  descriptions: string[];
};

type Props = {
  number: number;
  image: string;
  imgSide: 'left' | 'right';
  notes: Notes;
};

// Styles
const Container = styled('figure', {
  all: 'unset',
  display: 'flex',
  flexDirection: 'column',
  '@media only screen and (min-width: 600px)': {
    flexDirection: 'row',
  },
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1em',
});

const CaptionSection = styled('div', {
  flex: 3,
  minWidth: '46dvw',
  display: 'flex',
  gap: '1em',
});

const Number = styled('span', {
  fontFamily: '$OpenSans',
  fontSize: 'clamp(7rem, 14vw, 15rem)',
  fontWeight: '$600',
  color: '#d0d0ce',
  marginLeft: '1rem',
});

const Note = styled('figcaption', {
  display: 'grid',
  alignContent: 'space-e',
  fontFamily: '$Noto',
  margin: '2.5em 0.5em 1em 0.5em',
});

const Essentials = styled('h3', {
  fontSize: 'clamp(1.0625rem, 1.0069rem + 0.2778vw, 1.2rem)',
  paddingBottom: '1em',
});

const Descriptions = styled('div', {
  display: 'grid',
  alignContent: 'start',
  gap: '0.3em',
});

const Paragraph = styled('p', {
  fontSize: 'clamp(0.75rem, 0.6944rem + 0.2778vw, 0.9375rem)',
  lineHeight: 1.4,
  color: '$AnnotationText',
  boxSizing: 'border-box',
});

const Image = styled('img', {
  flex: 5,
  width: '80%',
  marginTop: '2em',
  '@media only screen and (min-width: 600px)': {
    width: '40%',
    marginTop: 'none',
  },
  filter: 'drop-shadow(-2px 2px 5px rgba(0,0,0,0.3))',
  marginLeft: '1em',
  variants: {
    side: {
      left: {
        '@media only screen and (min-width: 600px)': {
          order: '-1',
        },
      },
      right: {
        order: 0,
        marginLeft: 0,
      },
    },
  },
});

// DOM
export const Operation: React.FC<Props> = ({ number, image, imgSide, notes }) => (
  <Container>
    <CaptionSection>
      <Number>{number}</Number>
      <Note>
        <Essentials>{notes.essentials}</Essentials>
        <Descriptions>
          {notes.descriptions.map((d) => (
            <Paragraph>{d}</Paragraph>
          ))}
        </Descriptions>
      </Note>
    </CaptionSection>
    <Image src={image} alt="usageImage" side={imgSide === 'left' ? 'left' : 'right'} />
  </Container>
);
