import React, { Suspense } from 'react';
import { styled } from 'stitches.config';
import { useAtomValue } from 'jotai';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { user } from 'models/user/atom';

type Props = {
  children: React.ReactNode;
  onclick: () => void;
  offset: number;
};

export const SpeechBubble: React.FC<Props> = ({ children, onclick, offset }) => {
  const currentUser = useAtomValue(user);

  return (
    <TooltipPrimitive.Provider>
      <Suspense>
        <TooltipPrimitive.Root open={!currentUser}>
          <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
          <TooltipPrimitive.Portal>
            <TooltipContent side="top" sideOffset={offset} align="end">
              <FaMataLink onClick={onclick}>無料会員登録</FaMataLink>&nbsp;の後にご利用いただけます。
              <TooltipArrow />
            </TooltipContent>
          </TooltipPrimitive.Portal>
        </TooltipPrimitive.Root>
      </Suspense>
    </TooltipPrimitive.Provider>
  );
};

const TooltipContent = styled(TooltipPrimitive.Content, {
  borderRadius: '$2',
  border: '1px solid $DeloitteGreen',
  padding: '8px 19px',
  fontFamily: '$Noto',
  fontSize: 12,
  lineHeight: 1,
  letterSpacing: '0.03em',
  color: '#4C444D',
  backgroundColor: 'white',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const TooltipArrow = styled(TooltipPrimitive.Arrow, {
  fill: 'white',
  stroke: '$DeloitteGreen',
  strokeWidth: 2,
  transform: 'translateX(calc(10vw + 50px))',
});

const FaMataLink = styled('a', {
  all: 'unset',
  fontWeight: 'bold',
  cursor: 'pointer',
  textDecoration: 'underline',
  textDecorationColor: '$DeloitteGreen',
  textDecorationThickness: '0.2em',
  textDecorationOffset: '3px',
  textDecorationStyle: 'solid',
});
