import { FC } from 'react';
import { styled } from 'stitches.config';

import { ReactComponent as CircularImg } from 'assets/Word_wheel_largest_cities.svg';
import { HeroContent } from './HeroContent';

/**
 * ファーストビューのメインコンポーネントです。
 */

type Props = {
  actionJustBeforeSearch: () => void;
};

export const FirstView: FC<Props> = ({ actionJustBeforeSearch }) => (
  // DOM
  <Flex>
    <HeroContent actionJustBeforeSearch={actionJustBeforeSearch} />
    <CircularMotif />
  </Flex>
);

// Styles
const Flex = styled('div', {
  display: 'flex',
  justifyContent: 'space-around',
  width: 'min(1300px, 95dvw)',
  height: '80dvh',
});

const CircularMotif = styled(CircularImg, {
  display: 'none',
  '@media only screen and (min-width: 1100px)': {
    display: 'block',
    maxInlineSize: '45dvh',
    minInlineSize: '38%',
    blockSize: 'auto',
    marginTop: '2em',
    marginRight: '1.5em',
  },
});
