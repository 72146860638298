import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';

import { fetchDocument } from 'common/lib/documentApi';
import { query } from 'models/queryState/atoms';

/**
 * バックエンドから返却された検索結果の丸ごとのキャッシュを保持するatom。
 * queryの変更の伝播を受けると、バックエンドへのfetchが発火する。
 */
export const serverData = atom((get) => {
  const res = fetchDocument(get(query));
  return res;
});

// TODO: 全てのデータを保持するため、メモリを圧迫する。以下のような改善案。
// １、tfIdfScoresをcompressedSparseMatrixのまま最後まで扱う。
// ２、windowサイズの縮小にあわせてマップを再描画(単純な拡大縮小のみ？)しないようにし、クライアントで全データを持つ必要をなくす。
export const nodeIds = selectAtom(serverData, (d) => d.idx);
export const coordinates = selectAtom(serverData, (d) => d.embs);
export const wordsSets = selectAtom(serverData, (d) => d.invvocdict);
export const tfIdfScores = selectAtom(serverData, (d) => d.tfidf_scores);
export const artcleInfoAll = selectAtom(serverData, (d) => d.vhits);
