import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { PartialError } from 'common/components/errorFallback/PartialError';
import { errors } from 'common/components/errorFallback/errors';

export const AuthBoundary = ({ children }: { children: React.ReactNode }) => (
  <ErrorBoundary fallback={<PartialError errorCode={errors.userInfo.code} />}>
    <React.Suspense fallback={<div />}>{children}</React.Suspense>
  </ErrorBoundary>
);
