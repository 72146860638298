import React from 'react';
import { styled, keyframes } from 'stitches.config';

import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

import { TermsArea } from 'common/components/termsArea/TermsArea';
import { TermsText } from 'common/components/termsArea/TermsText';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  openChange: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TermsModal: React.FC<Props> = ({ isOpen, onConfirm, openChange }) => (
  <Dialog.Root open={isOpen} onOpenChange={openChange}>
    <Dialog.Portal>
      <DialogOverlay />
      <DialogContent>
        <TermsContainer>
          <TermsArea>
            <TermsText />
          </TermsArea>
          <Button type="button" onClick={onConfirm}>
            合意して検索する
          </Button>
        </TermsContainer>
        <Dialog.Close asChild>
          <IconButton aria-label="Close">
            <Cross2Icon width="25px" height="25px" />
          </IconButton>
        </Dialog.Close>
      </DialogContent>
    </Dialog.Portal>
  </Dialog.Root>
);

// Animations
const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

// Styles
const DialogOverlay = styled(Dialog.Overlay, {
  background: 'rgba(0 0 0 / 0.5)',
  position: 'fixed',
  zIndex: '$4',
  inset: 0,
  display: 'grid',
  placeItems: 'center',
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

const DialogContent = styled(Dialog.Content, {
  display: 'grid',
  placeItems: 'center',
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  zIndex: '$max',
  width: 'min(93dvw, 800px)',
  maxHeight: '80dvh',
  padding: '2em 0.5em',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflowY: 'auto',
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
});

const TermsContainer = styled('div', {
  display: 'grid',
  placeItems: 'center',
  gap: '4dvh',
});

const IconButton = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 30,
  width: 30,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  position: 'absolute',
  top: 10,
  right: 12,

  '&:hover': { backgroundColor: '$gray9' },
  '&:focus': { boxShadow: `0 0 0 2px $gray8` },
});

const Button = styled('button', {
  all: 'unset',
  backgroundColor: '$AccessibleGreen',
  borderRadius: '$2',
  color: 'white',
  fontSize: 'clamp(0.9rem, 0.58rem + 0.6481vw, 1.3125rem)',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  padding: 'min(3dvw, 1em) min(4dvh, 2em)',
  boxShadow: `-1px 1px 5px rgba(0,0,0,0.3)`,
  '&:hover': {
    filter: 'brightness(80%)',
    cursor: 'pointer',
    boxShadow: 'none',
  },
});
