import { createStitches } from '@stitches/react';
import '@fontsource/noto-sans-jp';
import '@fontsource/noto-sans-jp/100.css';
import '@fontsource/noto-sans-jp/300.css';
import '@fontsource/noto-sans-jp/400.css';
import '@fontsource/noto-sans-jp/700.css';
import '@fontsource/open-sans';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import { gray, slate, red, green } from '@radix-ui/colors';

import type * as Stitches from '@stitches/react';

export type { VariantProps } from '@stitches/react';
export type CSS = Stitches.CSS<typeof config>;

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  theme: {
    colors: {
      DeloitteGreen: '#86BC25',
      BrightGreen: '#0df200',
      AccessibleGreen: '#26890D',
      LightGreen: '#E1EEC8',
      Cream: '#F9F5E7',
      CalmWhite: '#d8deeb',
      LightGray: '#F3F3F3',
      FocusBlue: '#00a3e0',
      LinkBlue: '#0076a8',
      Text: '#1e2022',
      TextDarker: '#28292B',
      Disabled: '#97999b',
      AnnotationText: '#53565a',
      ...gray,
      ...slate,
      ...red,
      ...green,
    },
    fonts: {
      Noto: 'Noto Sans JP, Untitled Sans, Sans',
      OpenSans: 'Open Sans, Sans',
    },
    fontSizes: {
      head1: 'clamp(30px, 5vw, 70px)',
      head2: 'clamp(21px, 3vw, 49px)',
      body1: 'clamp(13.5px, 2vw, 25px)',
      body2: 'clamp(12px, 1.3vw, 20px)',
    },
    fontWeights: {
      100: 100,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      900: 900,
    },
    zIndices: {
      1: '100',
      2: '200',
      3: '300',
      4: '400',
      max: '999',
    },
    space: {
      1: '5px',
      2: '10px',
      3: '15px',
      4: '20px',
      5: '25px',
      6: '35px',
      7: '45px',
      8: '65px',
      9: '80px',
    },
    sizes: {
      1: '5px',
      2: '10px',
      3: '15px',
      4: '20px',
      5: '25px',
      6: '35px',
      7: '45px',
      8: '65px',
      9: '80px',
    },
    radii: {
      1: '4px',
      2: '6px',
      3: '8px',
      4: '12px',
      round: '50%',
      pill: '9999px',
    },
  },
  media: {
    sm: '(min-width: 520px)',
    md: '(min-width: 900px)',
    lg: '(min-width: 1200px)',
  },
  utils: {
    marginX: (value: number | string) => ({ marginLeft: value, marginRight: value }),
    marginY: (value: number | string) => ({ marginTop: value, marginBottom: value }),
    paddingX: (value: number | string) => ({ paddingLeft: value, paddingRight: value }),
    paddingY: (value: number | string) => ({ paddingTop: value, paddingBottom: value }),
  },
});

const injectGlobalStyles = globalCss({
  '*': { boxSizing: 'border-box' },
  html: {
    scrollBehavior: 'smooth',
    overflowX: 'hidden',
  },
  body: {
    margin: 0,
    padding: 0,
    backgroundColor: '$Cream',
  },
  h1: { margin: 0, padding: 0, lineHeight: 1 },
  h2: { margin: 0, padding: 0, lineHeight: 1 },
  h3: { margin: 0, padding: 0, lineHeight: 1 },
  h4: { margin: 0, padding: 0, lineHeight: 1 },
  p: { margin: 0, padding: 0, lineHeight: 1 },
  ul: { margin: 0, padding: 0 },
  li: { margin: 0, padding: 0 },
  'input[type="search"]': {
    WebkitAppearance: 'none',
  },
});

injectGlobalStyles();

// export very commons from config to import on a single line
export const Grid = styled('div', { display: 'grid' });
export const Flex = styled('div', { display: 'flex' });
export const Text = styled('span', {
  margin: '0',
  fontVariantNumeric: 'tabular-nums',
  display: 'block',
  variants: {
    noto: {
      true: { fontFamily: '$Noto' },
    },
    sans: {
      true: { fontFamily: '$OpenSans' },
    },
  },
});
