import React from 'react';
import { styled } from 'stitches.config';
import { useAtomValue } from 'jotai';

import { user } from 'models/user/atom';

import { LoginButton } from './LoginButton';
import { UserName } from './UserName';

import { handleLoginClicked } from '../helperLogic';
import { REGISTER_LINK } from '../constant';

// helper
const fullName = (last_name: string | null | undefined, first_name: string | null | undefined): string =>
    `${last_name ?? ''}${first_name ?? ''}` || '氏名未登録ユーザ';


// DOM
export const AuthMain = () => {
  const currentUser = useAtomValue(user);

  return currentUser ? (
    <UserName name={fullName(currentUser.last_name, currentUser.first_name)} />
  ) : (
    <ButtonGroup>
      <LoginButton label="ログイン" backgroundcolor="black" linkTo={handleLoginClicked} />
      <LoginButton label="新規登録" backgroundcolor="green" linkTo={REGISTER_LINK} />
    </ButtonGroup>
  );
};

// Styles
const ButtonGroup = styled('div', {
  display: 'grid',
  gap: 13,
  gridTemplateColumns: 'repeat(2, 1fr)',
  height: 'auto',
});
