import { useState, useEffect } from 'react';

// In
type UserActivity = {
  visits: number;
  searches: number;
  lastSurveyDate: Date;
  isAnsweredList: boolean[];
};

// Out
type NoSurveysToShow = null;
type SurveyULR = string | NoSurveysToShow;

// Hook Core
export const useSurveyUrl = (activity: UserActivity) => {
  const [url, setUlr] = useState<SurveyULR>(null);

  useEffect(() => {
    const urlToShow = decideSurveyUrl(activity, surveyUrls);
    setUlr(urlToShow);
  }, [activity]);

  const closeSurvey = () => {
    const updatedAns = getLatestAnswerStatus(activity.isAnsweredList, surveyUrls, url);
    const serializedAns = JSON.stringify(updatedAns);

    setUlr(null);

    // metrics reset
    localStorage.setItem('isAnsweredList', serializedAns);
    localStorage.setItem('lastSurveyDate', new Date().toString());
    localStorage.setItem('visits', '0');
    localStorage.setItem('searches', '0');
  };

  return {
    url,
    closeSurvey,
  };
};

// Constants
const surveyUrls = [
  'https://forms.office.com/Pages/ResponsePage.aspx?id=8UXaNizdH02vE1q-RrmZIZujoLrikdJItChMWcYf4RFUODE5UVVKVVdWRzUwQUNXWlNQRFdKTUg2Sy4u&embed=true',
  'https://forms.office.com/Pages/ResponsePage.aspx?id=8UXaNizdH02vE1q-RrmZIZujoLrikdJItChMWcYf4RFUMUhLU1RTMzI3TE9HV1lJWFBOWDBIVUdHQy4u&embed=true',
  'https://forms.office.com/Pages/ResponsePage.aspx?id=8UXaNizdH02vE1q-RrmZIZujoLrikdJItChMWcYf4RFUQjREMUhKOFRPNUUyU1ZLVVlJTFpWRklZQS4u&embed=true',
  'https://forms.office.com/Pages/ResponsePage.aspx?id=8UXaNizdH02vE1q-RrmZIZujoLrikdJItChMWcYf4RFURFBNRDk1VjFOV0VUVTlQRzcwNFdXNkdZRC4u&embed=true',
];

// Logics
// export for test
export const decideSurveyUrl = (metrics: UserActivity, urls: string[]): SurveyULR => {
  if (!enoughUse(metrics.visits, metrics.searches)) return null;
  if (!enoughInterval(metrics.lastSurveyDate)) return null;

  const latestAnswerStatus = zipAndSync(urls, metrics.isAnsweredList);
  const thisTimeUrl = selectRandomSurvey(latestAnswerStatus);

  return thisTimeUrl;

  // helpers
  function enoughUse(v: number, s: number) {
    return v >= 5 || s >= 5;
  }

  function enoughInterval(lastDate: Date) {
    const sinceLast = Date.now() - lastDate.getTime();
    const weekMs = 7 * 24 * 60 * 60 * 1000;
    return sinceLast > weekMs;
  }
};

type SurveyStatus = {
  url: string;
  shown: boolean;
};

const zipAndSync = (urls: string[], ansList: boolean[]): SurveyStatus[] =>
  urls.map((url, index) => ({
    url,
    shown: index < ansList.length ? ansList[index] : false,
  }));

const selectRandomSurvey = (surveys: SurveyStatus[]): SurveyULR =>
  surveys.every((item) => item.shown)
    ? null
    : surveys
        .filter((item) => !item.shown)
        .map((item) => item.url)
        .sort(() => 0.5 - Math.random())[0]; // ランダムに並び替えた先頭

// export for test
export const getLatestAnswerStatus = (oldList: boolean[], allUrls: string[], url: SurveyULR) => {
  const extendedAns = syncNumOfSurveys(oldList, allUrls.length);
  return updateAnsweredList(extendedAns, allUrls, url || 'no url match');

  // helpers
  function syncNumOfSurveys(old: boolean[], currentLength: number) {
    return old.concat(new Array(currentLength - old.length).fill(false));
  }
  function updateAnsweredList(a: boolean[], urls: string[], u: string) {
    const urlIndex = urls.indexOf(u);
    if (urlIndex !== -1) {
      return a.map((answer, index) => (index === urlIndex ? true : answer));
    }
    return a;
  }
};

// Related Logics For External Use
export const countUpSearches = () => {
  const searches = parseInt(localStorage.getItem('searches') || '0', 10);
  localStorage.setItem('searches', (searches + 1).toString());
};

export const countUpVisits = () => {
  const visits = parseInt(localStorage.getItem('visits') || '0', 10);
  const lastVisitDate = localStorage.getItem('lastVisitDate');
  const stayDuration = lastVisitDate ? Date.now() - parseInt(lastVisitDate, 10) : 0;
  if (lastVisitDate === null || stayDuration > 60000) {
    localStorage.setItem('visits', (visits + 1).toString());
    localStorage.setItem('lastVisitDate', new Date().toString());
  }
};
